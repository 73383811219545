<template>
  <div
    :style="cssVars"
    class="content-row">
    <slot/>
  </div>
</template>

<script>
export default {
  props: {
    spacing: {
      type: Number,
      default: 16
    }
  },
  computed: {
    cssVars () {
      return {
        '--horizontal-margin': `${this.spacing}px`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-row > :deep(*) {
  margin-left: var(--horizontal-margin);
  margin-right: var(--horizontal-margin);
}
</style>
