<template>
  <div
    class="email-capture-section row"
    :class="`bg-${variant}`">
    <HomepageSection
      title="Become an Armoire Insider"
      :paragraph="paragraphText">
      <template #default>
        <EmailCapture
          class="mb-xl mx-auto offset-1 col-8 d-flex flex-column align-items-center"
          success-header="Email submitted!"
          success-body="See you in your inbox"
          source="homepage"
          button-text="Sign Me Up!"/>
      </template>
    </HomepageSection>
  </div>
</template>

<script setup>
import EmailCapture from '@/homepage/components/revamp/EmailCapture.vue'
import HomepageSection from './HomepageSection.vue'

defineProps({
  variant: {
    default: 'origami',
    validator: v => v.match(/(white|origami)/),
    type: String
  }
})

const paragraphText = 'Be among the first to see new styles and get exclusive access to secret flash sales and seasonal perks.'
</script>
