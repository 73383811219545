<template>
  <transition
    name="fade"
    mode="out-in">
    <div
      v-if="section && styleColors && styleColors.length"
      class="container">
      <div
        class="closet-preview">
        <div
          v-if="showSectionTitle"
          class="section-title">
          <a
            target="_blank"
            :href="sectionLink">
            <h4>{{ section.name }}</h4>
          </a>
        </div>
        <div class="preview-links">
          <a
            v-if="length"
            target="_blank"
            :href="sectionLink">
            {{ length }} Items
          </a>
          <a
            target="_blank"
            :href="sectionLink">
            View all
          </a>
        </div>
        <product-scroll
          in-viewport-root-margin="20% 0%"
          :in-viewport-once="true"
          :style-colors="styleColors.slice(0, 20)"/>
      </div>
    </div>
    <div
      v-else
      key="placeholder"
      class="placeholder">
      <div
        v-for="index in 6"
        :key="`placeholder-image-${index}`"
        class="placeholder-image"/>
    </div>
  </transition>
</template>

<script>
import { buildUrl } from '@/homepage/utils/url'
import ProductScroll from './ProductScroll.vue'
import { slugify } from '@/utils/stringParsing.js'

export default {
  components: { ProductScroll },
  props: {
    sectionId: {
      type: String,
      required: true
    },
    showSectionTitle: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      section: {},
      sectionLength: 24,
      length: null,
      styleColors: []
    }
  },
  computed: {
    sectionLink () {
      const sluggedSectionName = slugify(this.section.name || '')
      const params = []
      // Request from marketing to link to full section, uncomment to reset limit
      // params.push(`m=${this.sectionLength}`)
      if ('referralCode' in this.$route.params) params.push(`r=${this.$route.params.referralCode}`)
      if ('s' in this.$route.query) params.push(`s=${this.$route.query.s}`)

      const paramsParsed = params.length > 0 ? `?${params.join('&')}` : ''
      return `/closet/u/${sluggedSectionName}/${this.sectionId}${paramsParsed}`
    }
  },
  created () {
    const fetchOptions = process.env.VUE_APP_BUILD_TIME
      ? { headers: { 'APP-BUILD-TIME': process.env.VUE_APP_BUILD_TIME } }
      : {}

    // delay a bit to allow the rest of the site to load faster
    setTimeout(() => {
      fetch(buildUrl(`/api/stylecolorsections/${this.sectionId}/`), fetchOptions)
        .then(res => res.json())
        .then(section => {
          this.section = section
        })
      fetch(buildUrl(`/api/stylecolorsections/${this.sectionId}/style_colors/?offset=0&amount=${this.sectionLength}&filter_version=2`), fetchOptions)
        .then(res => res.json())
        .then(data => {
          this.styleColors = data.styleColors
          this.length = data.sectionLength
        })
    }, 300)
  }
}
</script>

<style lang="scss" scoped>
.closet-preview-wrapper {
  padding: 36px 18px;
}

.closet-preview {
  max-width: 1100px;
  width: 100%;
}

.section-title {
  text-align: left;
  font-weight: bold;
  margin-left: 24px;

  @include medium {
    margin-left: 48px;
  }

  h4 {
    margin: 0.5em 0;
  }
}

.container {
  display: flex;
  justify-content: center;
}

.preview-links {
  display: flex;
  justify-content: space-between;
  margin: 16px 24px;

  @include medium {
    margin: 16px 48px;
  }

  a {
    color: $orchid;
    text-decoration: underline;
    &:hover {
      color: $plum;
    }
  }
}

.placeholder {
  display: flex;
  overflow: hidden;
  justify-content: center;
  width: 100%;
  max-width: 100vw;
  padding-top: 32px;

  @include medium {
    max-width: 1100px;
  }
}

.placeholder-image {
  width: 150px;
  height: 202px;
  background-color: $origami;
  border: .5px solid $ash;
  margin-right: 24px;
}

h2 {
  margin: 16px;
}
</style>
