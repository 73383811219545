<template>
  <div class="plans-wrapper d-flex gap-md gap-md-xxl mb-xl justify-content-center align-items-center text-center">
    <div
      v-for="plan in plans"
      :key="plan.name">
      <h5 class="caps mb-md">
        {{ plan.tagLine }}
      </h5>
      <PlanCard :plan="plan"/>
    </div>
  </div>
</template>

<script setup>
import PlanCard from '@/homepage/components/revamp/PlansForEveryBudget/PlanCard.vue'

const plans = window.armoire.plans
</script>

<style scoped lang="scss">
.plans-wrapper {
  flex-wrap: wrap;
}
</style>
