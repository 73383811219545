<template>
  <homepage-section id="plans">
    <content-wrapper :max-width="1200">
      <content-stack class="w-100">
        <content-wrapper :max-width="800">
          <content-stack :spacing="10">
            <homepage-text
              tag="h2"
              class="text-white"
              styling-variant="heading1">
              {{ headerText() }}
            </homepage-text>
            <homepage-text class="text-white">
              {{ subHeaderText() }}
            </homepage-text>
          </content-stack>
        </content-wrapper>
        <content-row
          class="flex-wrap">
          <content-stack
            v-for="plan in plans"
            :key="plan.name"
            :spacing="12"
            class="plan border-top">
            <homepage-text
              tag="h3"
              styling-variant="heading5"
              class="text-white">
              {{ plan.tagLine }}
            </homepage-text>
            <div class="plan-card">
              <div class="plan-card-inner">
                <div
                  v-if="plan.mostPopular"
                  class="most-loved">
                  <homepage-text
                    class="semi-bold"
                    styling-variant="copy-tiny">
                    Best Value
                  </homepage-text>
                </div>
                <content-stack :spacing="12">
                  <div class="plan-name">
                    <decorative-divider
                      :height="2.5"
                      class="divider left"
                      variant="horizontal"/>
                    <content-wrapper :max-width="180">
                      <homepage-text
                        styling-variant="heading2">
                        {{ plan.name }}
                      </homepage-text>
                    </content-wrapper>
                    <decorative-divider
                      :height="2.5"
                      class="divider right"
                      variant="horizontal"/>
                  </div>
                  <content-row>
                    <div class="price-text">
                      <span
                        v-if="plan.promoPrice !== plan.basePrice || referralCampaign">
                        <b>{{ getPlanPromoPrice(plan) }} first month</b> ({{ plan.basePrice }}/month after)
                      </span>
                      <span v-else>
                        <b>{{ plan.basePrice }} per month</b>
                      </span>
                    </div>
                  </content-row>
                  <content-stack :spacing="2">
                    <homepage-text
                      v-for="feature in plan.features"
                      :key="`${plan.name}-feature-${feature}`"
                      styling-variant="copy-tiny">
                      <span v-html="feature"/>
                    </homepage-text>
                  </content-stack>
                  <primary-button
                    tag="a"
                    type="a"
                    :href="plan.signupUrl"
                    target="_self"
                    class="try-it-button">
                    {{ plan.buttonName }} Plan
                  </primary-button>
                </content-stack>
              </div>
            </div>
            <homepage-text
              v-if="referralCampaign"
              tag="h3"
              styling-variant="heading5"
              class="text-white">
              Save {{ getReferralSave(plan) }}
            </homepage-text>
          </content-stack>
        </content-row>
        <content-stack class="border-top">
          <homepage-text
            tag="h3"
            styling-variant="heading3"
            class="text-white">
            Every plan includes:
          </homepage-text>
          <content-row
            :spacing="8"
            class="flex-wrap justify-content-between">
            <content-stack
              v-for="feature in planFeatures"
              :key="feature.title"
              class="plan-feature"
              :spacing="4">
              <svg-check variant="inverse"/>
              <homepage-text
                styling-variant="copy-small"
                class="text-white feature-text">
                {{ feature.title }}<span v-if="feature.title2"><br v-if="!isMobile"> {{ feature.title2 }}</span>
              </homepage-text>
            </content-stack>
          </content-row>
        </content-stack>
      </content-stack>
    </content-wrapper>
  </homepage-section>
</template>

<script>
import ContentRow from './ContentRow.vue'
import ContentStack from './ContentStack.vue'
import ContentWrapper from './ContentWrapper.vue'
import HomepageText from './HomepageText.vue'
import HomepageSection from './HomepageSection.vue'
import SvgCheck from './SvgCheck.vue'
import PrimaryButton from './PrimaryButton.vue'
import DecorativeDivider from './DecorativeDivider.vue'

import { getReferralSavedAmount, getReferralDiscountPrice } from '@/homepage/utils/referrals.js'
import { formatPrice } from '@/utils/stringParsing.js'

export default {
  components: {
    ContentRow,
    ContentStack,
    ContentWrapper,
    HomepageText,
    HomepageSection,
    SvgCheck,
    PrimaryButton,
    DecorativeDivider
  },
  props: {
    referrer: {
      type: Object,
      default: null
    },
    referralCode: {
      type: String,
      default: null
    },
    referralCampaign: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      plans: window.armoire.plans,
      planFeatures: [
        {
          title: 'Free shipping & returns'
        },
        {
          title: 'Access to our full inventory'
        },
        {
          title: 'Coverage of minor damages'
        },
        {
          title: 'Free cleaning',
          title2: '(No laundry!)'
        },
        {
          title: 'Ability to cancel or pause anytime'
        },
        {
          title: 'Free personal styling'
        }
      ]
    }
  },
  created () {
    if (this.referralCode) {
      for (const plan in this.plans) {
        this.plans[plan].signupUrl += `&r=${this.referralCode}`
      }
    }
  },
  methods: {
    headerText () {
      if (this.referrer) {
        return 'Choose your membership'
      }
      return 'Plans for every budget'
    },
    subHeaderText () {
      if (this.referrer) {
        return this.referrer.firstName + '\'s referral gets you our best deal, no matter what plan you choose! You won\'t find a better deal anywhere else.'
      }
      return 'All plans allow you to keep your at-home items until your new case arrives, so you\'re never without a great outfit!'
    },
    getReferralSave (plan) {
      const amount = getReferralSavedAmount(this.referralCampaign, plan)
      return formatPrice(amount, true)
    },
    getPlanPromoPrice (plan) {
      if (!this.referralCampaign) return plan.promoPrice
      const amount = getReferralDiscountPrice(this.referralCampaign, plan)
      return formatPrice(amount, true)
    }
  }
}
</script>

<style lang="scss" scoped>
.plan-cards {
  width: 100%;
}

.plan {
  margin-bottom: 16px;
  width: 333px;
}

.plan-feature {
  width: 45%;
  height: 100px;

  @include medium {
    width: 30%;
  }

  @include extralarge {
    width: 156px;

    .feature-text {
      font-size: $font-size-base;
      line-height: $font-size-base * 1.25;
    }
  }
}

.plan-name {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .divider {
    flex-shrink: 1;
    margin-top: 6px;

    &.left {
      margin-left: 16px;
    }

    &.right {
      margin-right: 16px;
    }
  }
}

.price-text {
  color: $plum;
  font-family: $body-font-family;
  &.b{
    font-weight: 600;
  }
  margin: 0 8px;
}

.plan-card {
  background-color: $white;
  padding: 16px;

  .plan-card-inner {
    position: relative;
    border: 8px solid $champagne;

    .most-loved {
      z-index: 2;
      padding: 8px;
      top: -24px;
      width: 168px;
      position: absolute;
      left: 50%;
      margin-left: -84px;
      background-color: $champagne;
      color: $plum;
    }
  }
}

.try-it-button {
  padding: 12px 0;
  width: 85%;
  font-size:14px;
}
</style>
