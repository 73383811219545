<template>
  <div
    v-if="!isMobile"
    class="faq-switcher"
    :class="{ redesign: showVariant }">
    <div class="faq-switcher-categories">
      <button
        v-for="category in categoryKeys"
        :key="category"
        class="faq-category"
        :class="{ 'selected': selectedCategorySwitcher === category }"
        @click="setSelectedCategorySwitcher(category)">
        {{ faq[category].title }}
      </button>
    </div>
    <transition
      mode="out-in"
      :name="`slide-${transitionDirection}`">
      <div
        :key="selectedCategorySwitcher"
        class="faq-switcher-content">
        <div
          v-for="(qAndA, index) in selectedCategorySwitcherData"
          :key="index"
          class="faq">
          <p class="question">
            {{ qAndA.question }}
          </p>
          <p
            class="answer"
            v-html="qAndA.answer"/>
        </div>
      </div>
    </transition>
  </div>
  <div
    v-else
    class="faq-dropdowns"
    :class="{ redesign: showVariant }">
    <div class="faq-dropdown-categories">
      <div
        v-for="category in categoryKeys"
        :key="category">
        <button
          :id="category"
          class="faq-dropdown-button"
          :class="{ 'open': openCategoryDropdown === category }"
          @click="setOpenCategoryDropdown(category)">
          {{ faq[category].title }}
          <div class="icon-container">
            <svg-chevron-down
              class="chevron"
              :class="{ 'up': openCategoryDropdown === category }"/>
          </div>
        </button>
        <div
          class="faq-dropdown-content"
          :class="{ 'visible': openCategoryDropdown === category }">
          <div
            v-for="(qAndA, index) in faq[category].data"
            :key="index"
            class="faq">
            <p class="question">
              {{ qAndA.question }}
            </p>
            <p
              class="answer"
              v-html="qAndA.answer"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useFaq from '@/homepage/composables/faq.js'
import SvgChevronDown from '@/homepage/components/common/SvgChevronDown.vue'
import { useHomepageRedesignExperiment } from '@shared/composables/experiment.js'
import useScreenSize from '@shared/composables/screenSize.js'

export default {
  components: {
    SvgChevronDown
  },
  setup () {
    const { showVariant } = useHomepageRedesignExperiment()
    const { faq } = useFaq()
    const { isMobile } = useScreenSize()

    return {
      showVariant,
      faq,
      isMobile
    }
  },
  data () {
    const currentCategory = this.$route.query.category ?? 'basics'
    return {
      prevCategorySwitcher: currentCategory,
      selectedCategorySwitcher: currentCategory,
      openCategoryDropdown: currentCategory
    }
  },
  computed: {
    categoryKeys () {
      return Object.keys(this.faq)
    },
    selectedCategorySwitcherData () {
      return this.faq[this.selectedCategorySwitcher].data
    },
    transitionDirection () {
      return this.categoryKeys.indexOf(this.prevCategorySwitcher) < this.categoryKeys.indexOf(this.selectedCategorySwitcher) ? 'left' : 'right'
    }
  },
  watch: {
    selectedCategorySwitcher: {
      handler (category) {
        this.$router.push({ query: { category } })
      },
      immediate: true
    },
    openCategoryDropdown: {
      handler (category) {
        const query = category ? { category } : {}
        this.$router.push({ query })
      },
      immediate: true
    },
    isMobile: {
      handler (isMobile) {
        if (isMobile) {
          this.openCategoryDropdown = this.selectedCategorySwitcher
        } else {
          this.selectedCategorySwitcher = this.openCategoryDropdown ?? this.categoryKeys[0]
        }
      }
    },
    '$route.query.category': {
      handler (category) {
        // This watcher is still active on other routes due to KeepAlive
        // so we need to make sure we're on the correct page first.
        if (this.$route.name !== 'frequently-asked-questions') {
          return
        }
        if (!this.categoryKeys.includes(category)) {
          if (category || !this.isMobile) {
            category = this.categoryKeys[0]
          }
        }
        if (this.isMobile) {
          this.openCategoryDropdown = category
        } else {
          this.selectedCategorySwitcher = category
        }
      }
    }
  },
  activated () {
    this.$router.replace({ query: { category: this.selectedCategorySwitcher } })
  },
  methods: {
    setOpenCategoryDropdown (categoryKey) {
      if (categoryKey === this.openCategoryDropdown) {
        this.openCategoryDropdown = null
      } else {
        this.openCategoryDropdown = categoryKey
        // Situates the open faq category at the top of the page on mobile
        this.$nextTick(() => {
          const element = document.getElementById(categoryKey)
          // Without the timeout, the scrollIntoView method doesn't work.
          setTimeout(() => element.scrollIntoView(), 0)
        })
      }
    },
    setSelectedCategorySwitcher (categoryKey) {
      this.prevCategorySwitcher = this.selectedCategorySwitcher
      this.selectedCategorySwitcher = categoryKey
    }
  }
}
</script>

<style lang="scss" scoped>
.faq-detailed {
  background: $secondary;
  padding: 36px 12px;

  @include medium {
    padding: 64px 0;
  }

  .section-header {
    margin-top: 0;
    padding: 0 16px;
  }
}

.faq-dropdowns {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 0 auto;

  @include medium {
    display: none;
  }

  .faq-dropdown-button {
    width: 100%;
    border: 1px solid $primary;
    border-radius: $system-border-radius;
    font-family: $headings-font-family;
    font-size: $body-font-size;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    letter-spacing: .2em;
    cursor: pointer;
    color: $plum;
    background-color: $origami;
    text-align: left;
    margin: 12px 0;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    scroll-margin-top: 100px;

    &:focus {
      outline: none;
    }

    &.open, &:active {
      background-color: $plum;
      color: $white;
      font-weight: $font-weight-bold;
    }

    .button-text {
      width: 100%;
    }

    .icon-container {
      align-self: center;

      .chevron {
        width: 14px;
        height: 8px;
        @include transition-base-all;

        &.up {
          color: white;
          transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
        }
      }
    }
  }

  .faq-dropdown-content {
    max-height: 0;
    overflow: hidden;

    &.visible {
      max-height: 3000px;
    }

    .faq {
      width: 100%;
      margin: 2rem 0;
    }
  }
}

.faq-switcher {
  display: none;

  @include medium {
    display: block;
  }

  .faq-switcher-categories {
    max-width: 772px;
    width: 100%;
    margin: 40px auto;
    display: flex;
    justify-content: space-between;
    border: 1px solid $primary;
    border-radius: $system-border-radius;
    border-right: none;

    .faq-category {
      margin: 0;
      width: 20%;
      padding: 8px 0;
      font-family: $headings-font-family;
      text-transform: uppercase;
      letter-spacing: .2em;
      font-weight: $font-weight-bold;
      text-decoration: none;
      border: none;
      color: $plum;
      background-color: $origami;
      cursor: pointer;
      border-right: 1px solid $plum;

      &:focus {
        outline: none;
      }
    }

    .selected {
      background-color: $plum;
      color: $white;
    }
  }

  .faq-switcher-content {
    max-width: 920px;
    width: 90vw;
    margin: 60px auto 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: $spacing-lg;
  }
}

.faq {
  width: 400px;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;

  .question {
    font-weight: $font-weight-bold;
    margin: 0;
  }

  :deep(a) {
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }
}

.redesign {
  &.faq-switcher, &.faq-dropdowns {
    .faq-switcher-categories {
      margin: 0 auto $spacing-xxl auto;
    }

    .faq-switcher-content {
      width: 100%;
      margin: unset;

      .faq {
        max-width: 400px;
        width: calc((100% - #{$spacing-lg}) / 2);
      }
    }

    .faq-category {
      text-transform: unset;
      letter-spacing: unset;
      padding: 0px $spacing-xs;
      height: 50px;
    }

    .faq-dropdowns {
      width: unset;
      margin: unset;
    }

    .faq-dropdown-button {
      text-transform: unset;
      letter-spacing: unset;
      margin: unset;
      @include anchor-link($spacing-lg);
    }

    .faq-dropdown-categories {
      display: flex;
      flex-direction: column;
      gap: $spacing-xl;
    }

    .faq-dropdown-content {
      display: flex;
      flex-direction: column;
      gap: $spacing-xl;

      .faq {
        margin: unset;

        &:first-child {
          margin-top: $spacing-lg;
        }
      }
    }
  }
}
</style>
