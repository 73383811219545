<template>
  <div class="how-armoire-works">
    <h2>How Armoire Works</h2>
    <div class="steps-wrapper">
      <div
        class="steps">
        <div
          v-for="(step, index) in steps"
          :key="'step-' + index"
          class="step">
          <h3 class="step-number">
            {{ index + 1 }}
          </h3>
          <h6 class="step-title">
            {{ step.title }}
          </h6>
          <div>
            <img :src="getImageUrl(`referral/icon-howitworks-${index + 1}.svg`)">
          </div>
          <p>
            {{ step.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getImageUrl } from '@/homepage/utils/url.js'

export default {
  name: 'HowArmoireWorks',
  data () {
    return {
      steps: [
        {
          title: 'Choose your styles',
          description: 'Take our style quiz and choose styles from your curated closet. We\'ll ship them right to your door.'
        },
        {
          title: 'Wear & have fun!',
          description: 'Wear your styles as long as you want. Laundry and minor repairs or spills are always on us!'
        },
        {
          title: 'Refresh (it\'s easy!)',
          description: 'When you\'re ready, choose your next case. You can keep your styles until your new case arrives!'
        }
      ]
    }
  },
  methods: {
    getImageUrl
  }
}
</script>

<style lang="scss" scoped>
  .how-armoire-works {
    padding: 24px 0 48px;
  }

  .steps-wrapper {
    display: flex;
    justify-content: center;
  }

  .steps {
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include medium {
      flex-direction: row;
      max-width: 1200px;
    }
  }

  .step {
    margin: 12px 36px;

    @include medium {
      margin: 0 24px;
    }
  }

  .step-title {
    margin: 12px 0;
  }

  .step-number {
    margin: 0;
    font-weight: bold;
    color: $velvet;
  }
</style>
