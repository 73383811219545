<template>
  <div class="details-section">
    <h2 class="headline">
      We’re committed to protecting our planet,
      starting with the way we get dressed.
    </h2>
    <div class="iconEntries">
      <div
        v-for="(iconEntry, idx) in iconEntries"
        :key="idx"
        class="iconEntry">
        <img
          class="icon-image"
          :alt="iconEntry.alt"
          :src="getImageUrl(iconEntry.iconUrl)">
        <p class="icon-text">
          {{ iconEntry.text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getImageUrl } from '@/homepage/utils/url.js'
export default {
  name: 'SustainabilityDetailsIcons',
  data () {
    return {
      iconEntries: [
        {
          text: 'By leveraging the shared clothing economy, we’re reducing the 60% of clothing that ends up in incinerators or landfills within a year of being made.',
          iconUrl: 'sustainability/sustainable-bag.svg',
          alt: 'Icon of shopping bag with a recyling symbol on it'
        },
        {
          text: 'The carbon emissions from purchasing a pair of jeans? 30.6 lbs. Rent the same pair and emissions drop to just 5.5 lbs of CO2.',
          iconUrl: 'sustainability/emissions.svg',
          alt: 'Icon of emissions cloud'
        },
        {
          text: '1.7 billion pieces of clothing are not worn each year. The average Armoire garment is worn 19.2 times.',
          iconUrl: 'icons/flexible-returns.svg',
          alt: 'Icon of a jacket'
        }
      ]
    }
  },
  methods: {
    getImageUrl
  }
}
</script>

<style lang="scss" scoped>
.details-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px 24px;

  @include medium {
    padding: 48px;
  }
}

.headline {
  margin: 0 auto;
  max-width: 800px;
}

.iconEntries {
  max-width: 1200px;
  margin: 32px auto 0;
  display: flex;
  flex-direction: column;

  @include medium {
    justify-content: space-between;
    flex-direction: row;
  }

}

.iconEntry {
  width: 100%;
  max-width: 340px;
  text-align: center;
  margin: 24px 0;

  @include medium {
    width: 33%;
    padding: 2%;
  }

  .icon-image {
    margin-top: 4px;
    height: 64px;
     width: auto;
  }

  .icon-text {
    text-align: center;
    margin: 24px 0 0;
  }
}

</style>
