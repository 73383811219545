<template>
  <div
    :style="cssVars"
    class="content-wrapper">
    <slot/>
  </div>
</template>

<script>
export default {
  props: {
    maxWidth: {
      type: Number,
      default: 1040
    }
  },
  computed: {
    cssVars () {
      return {
        '--content-max': `${this.maxWidth}px`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--content-max);
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
