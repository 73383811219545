<template>
  <homepage-section
    class="variety-convenience"
    variant="white">
    <content-stack>
      <decorative-divider variant="top"/>
      <content-wrapper>
        <content-stack :spacing="isMobile ? 8 : 32">
          <content-wrapper :max-width="686">
            <content-stack
              class="section-wrapper">
              <homepage-text
                tag="h2"
                class="text-center"
                styling-variant="heading1">
                Variety meets convenience
              </homepage-text>
              <homepage-text
                tag="p"
                class="text-center"
                styling-variant="copy-small">
                Work clothes rental? Check. Weekend casual? Check. Formal wear or dress rentals? Of course! The perfect outfit for everywhere you need to be is at your fingertips. See what our members are renting.
              </homepage-text>
            </content-stack>
          </content-wrapper>
          <content-stack :spacing="8">
            <homepage-text
              tag="h3"
              class="text-center"
              styling-variant="heading2">
              Top member picks
            </homepage-text>
            <closet-preview-style-colors
              class="section-preview"
              :show-section-title="false"
              section-id="4KONx5kj5Z"/>
          </content-stack>
          <homepage-get-started-lockup
            location="Variety Convenience"
            class="section-wrapper">
            Make your rental closet personal by<br> taking our Style Quiz.
          </homepage-get-started-lockup>
        </content-stack>
      </content-wrapper>
    </content-stack>
  </homepage-section>
</template>

<script>
import ClosetPreviewStyleColors from '@/homepage/components/common/ClosetPreviewStyleColors.vue'
import ContentStack from '@/homepage/components/common/ContentStack.vue'
import ContentWrapper from '@/homepage/components/common/ContentWrapper.vue'
import DecorativeDivider from '@/homepage/components/common/DecorativeDivider.vue'
import HomepageSection from '@/homepage/components/common/HomepageSection.vue'
import HomepageText from '@/homepage/components/common/HomepageText.vue'
import HomepageGetStartedLockup from '@/homepage/components/common/HomepageGetStartedLockup.vue'

import inViewport from 'vue-in-viewport-mixin'

export default {
  components: {
    ContentWrapper,
    HomepageSection,
    HomepageText,
    ContentStack,
    DecorativeDivider,
    ClosetPreviewStyleColors,
    HomepageGetStartedLockup
  },
  mixins: [inViewport]
}
</script>

<style lang="scss" scoped>
.variety-convenience {
  padding-right: 0;
  padding-left: 0;
}

.section-preview {
  margin-bottom: 36px;
  width: 100%;
  max-width: 100vw;
}
.section-wrapper {
  padding: 0 20px;
}
</style>
