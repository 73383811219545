<template>
  <div
    class="closet-preview-wrapper">
    <h2>Preview the Closet</h2>
    <p class="intro-text">
      We carry thousands of high-end styles, here are a few of our favorites.
    </p>
    <closet-preview-style-colors
      class="section-preview"
      :section-id="sectionId"/>
  </div>
</template>

<script>
import ClosetPreviewStyleColors from './ClosetPreviewStyleColors.vue'

export default {
  name: 'ClosetPreview',
  components: {
    ClosetPreviewStyleColors
  },
  data () {
    return {
      sectionId: '4KONx5kj5Z',
      section: {},
      sectionLength: 32,
      styleColors: []
    }
  }
}
</script>

<style lang="scss" scoped>
.intro-text {
  padding: 0 18px;
}

.section-preview {
  margin-bottom: 36px;
  width: 100%;
  max-width: 100vw;
}
</style>
