<template>
  <div class="row bg-origami">
    <HomepageSection
      :title="title"
      :paragraph="paragraphText"/>
    <PlansSection/>
    <PlanIncludes/>
  </div>
</template>

<script setup>
import PlansSection from '@/homepage/components/revamp/PlansForEveryBudget/PlansSection.vue'
import PlanIncludes from '@/homepage/components/revamp/PlansForEveryBudget/PlanIncludes.vue'
import HomepageSection from './HomepageSection.vue'
defineProps({
  title: {
    type: String,
    default: 'Plans For Every Budget'
  }
})
const paragraphText = 'All plans allow you to keep your at-home items until your new case arrives, so you\'re never without a great wardrobe!'
</script>
