import { ref } from 'vue'

const ids = {}

export default function useComponentID (name) {
  if (!ids[name]) {
    ids[name] = 0
  }
  const componentID = ref(`${name}-${ids[name]++}`)
  return componentID
}
