<template>
  <homepage-section
    variant="white"
    :class="{'spacing-overide' : showVariant}">
    <content-wrapper>
      <content-stack :spacing="showVariant ? 0 : 32">
        <decorative-divider v-if="!showVariant"/>
        <content-stack :spacing="showVariant ? 0 : 2">
          <HomepageRedesignExperiment>
            <template #variantRedesign>
              <h2 class="text-center">
                Questions?<br>We have answers
              </h2>
            </template>
            <template #default>
              <homepage-text
                tag="h2"
                styling-variant="heading1">
                Questions? We have answers
              </homepage-text>
            </template>
          </HomepageRedesignExperiment>
        </content-stack>
        <div
          v-if="showVariant"
          class="separator"
          :class="{'mobile-separator-width': isMobile}"/>
        <accordion-boxes
          :class="{'boxes' : showVariant}"
          :boxes="faqs"/>
        <homepage-text
          :styling-variant="isMobile ? 'copy' : 'copy-small'">
          More questions?<br v-if="isMobile">
          <text-link
            class="full-faq"
            type="a"
            label="Check out the full FAQ"
            target="_self"
            href="/frequently-asked-questions">
            Check out the full FAQ
          </text-link>
        </homepage-text>
        <decorative-divider
          v-if="!showVariant"
          variant="bottom"/>
      </content-stack>
    </content-wrapper>
  </homepage-section>
</template>

<script>
import ContentStack from '@/homepage/components/common/ContentStack.vue'
import ContentWrapper from '@/homepage/components/common/ContentWrapper.vue'
import HomepageText from '@/homepage/components/common/HomepageText.vue'
import HomepageSection from '@/homepage/components/common/HomepageSection.vue'
import DecorativeDivider from '@/homepage/components/common/DecorativeDivider.vue'
import TextLink from '@/homepage/components/common/HomepageTextLink.vue'
import AccordionBoxes from './AccordionBoxes.vue'
import HomepageRedesignExperiment from '@shared/components/HomepageRedesignExperiment.vue'
import { useHomepageRedesignExperiment } from '@shared/composables/experiment.js'

export default {
  components: {
    AccordionBoxes,
    ContentStack,
    ContentWrapper,
    HomepageText,
    HomepageSection,
    DecorativeDivider,
    TextLink,
    HomepageRedesignExperiment
  },
  setup () {
    const { showVariant } = useHomepageRedesignExperiment()
    return {
      showVariant
    }
  },
  data () {
    return {
      faqs: [
        {
          header: 'How does this work?',
          text: 'Armoire is a clothing rental service that gives ' +
          'members access to endless styles for a monthly fee. ' +
          'Shipping and cleaning are always free.'
        },
        {
          header: 'How many items come in my case?',
          text: 'Our current plans allow you to choose 4, 6, or 7 items per case. You\'re also able to upgrade ' +
          'to larger cases at any point.'
        },
        {
          header: 'How many cases do I get each month?',
          text: 'It\'s up to you! With our unlimited plan, you\'re welcome to exchange items every week if you like. Capsule plan members are eligible for a new case every 30 days. Unused exchanges always roll over to the next month on our Capsule plan.'
        },
        {
          header: 'How much does it cost to be a member?',
          text: `Capsule memberships range from ${window.armoire.plans.capsule.basePrice}-${window.armoire.plans['capsule-7'].basePrice} a month, and Unlimited memberships are ${window.armoire.plans.unlimited.basePrice} a month. We offer discounted trial pricing for new members on all of our plans.`
        },
        {
          header: 'How are my items chosen?',
          text: 'You pick what ships. We curate a selection for you with a combination ' +
          'of machine learning and input from our professional stylists — but you always ' +
          'choose what you get. '
        },
        {
          header: 'What if I need a break or want to change my plan?',
          text: 'There is no commitment with Armoire. You can change, pause or cancel ' +
          'your membership at any time. '
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.full-faq {
  display: block;
  padding-top: 8px;

  @include medium {
    display: inline-block;
    padding-top: 0;
  }
}
.boxes {
  margin-top: 10px !important;
  margin-bottom: 40px !important;
}
.separator {
  width: calc(100% - 50px);
  background-color: $velvet;
  height: 1px;
  margin-top: 40px !important;
}
.mobile-separator-width {
  width: 100% !important;
}
.spacing-overide {
  padding-bottom: 64px !important;
}
</style>
