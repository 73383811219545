// Variables that are used to determine the environment we're running in.

export const isBackendProd = window.armoire?.isProd === true

export const isHostStaging = window.location.hostname.startsWith('staging')
export const isHostBeta = window.location.hostname.startsWith('beta')
export const isHostLocal = window.location.hostname.startsWith('localhost')

export const isProd = isBackendProd && !isHostStaging && !isHostBeta
export const isBeta = isBackendProd && isHostBeta
