<template>
  <component
    :is="type"
    :to="to"
    :href="href"
    :tag="tag"
    class="button"
    @click="$emit('click')">
    <slot/>
  </component>
</template>

<script>
export default {
  name: 'DefaultButton',
  props: {
    type: {
      type: String,
      default: 'a',
      validator: value => {
        return value.match(/(button|a|router-link|)/)
      }
    },
    tag: {
      type: String,
      default: 'a',
      validator: value => {
        return value.match(/(button|a)/)
      }
    },
    to: {
      type: Object,
      default: null
    },
    href: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>

.button {
  margin: 0px auto;
  padding: 12px 24px;
  background-color: $default-button-background-color;
  color: $default-button-text;
  font-family: $buttons-font-family;
  border-radius: $system-border-radius;
  font-size: $button-font-size-mobile;
  font-weight: $font-weight-bold;
  letter-spacing: $button-letter-spacing;
  line-height: $button-line-height;
  cursor: pointer;
  text-transform: uppercase;
  display: inline-block;
  border: none;
  outline: none;

  @include transition-base-all;

  &:hover {
    background-color: $default-button-background-hover;
    color: $default-button-text-hover;
  }

  @include medium {
    font-size: $button-font-size-desktop;
    padding: 16px 32px;
  }
}

</style>
