<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <title>X enclosed in a circle</title>
    <circle
      cx="16"
      cy="16.0011"
      r="16"
      class="circle"/>
    <path
      class="x"
      d="M9 9.0011L22.9999 23.001"
      :stroke-width="strokeWidth"/>
    <path
      class="x"
      d="M9 23.0011L22.9999 9.00121"
      :stroke-width="strokeWidth"/>
  </svg>
</template>

<script>
export default {
  name: 'SvgCheck',
  props: {
    variant: {
      type: String,
      default: 'danger',
      validator: value => {
        return value.match(/(danger)/)
      }
    },
    size: {
      type: Number,
      default: 24
    },
    strokeWidth: {
      type: Number,
      default: 4
    }
  }
}
</script>

<style lang="scss" scoped>
  .circle {
    fill: $danger-dark
  }

  .x {
    stroke: $white;
  }
</style>
