<template>
  <content-row
    :spacing="4"
    :class="{ 'expanded': expanded, 'border-top' : !isMobile }"
    class="brand-grid flex-wrap">
    <plain-button
      v-for="brand in highlightedBrands"
      :key="brand.id"
      type="a"
      class="brand-button"
      :label="`Link to brand page for ${brand.name}`"
      role="link"
      :href="`/closet/brand/${brand.brandLink}`">
      <homepage-image
        width="100%"
        height="auto"
        class="brand-member"
        :alt="`Link to brand page for ${brand.name}`"
        :webp-source="`brands/members/${brand.id}.webp`"
        :source="`brands/members/${brand.id}.jpg`"
        source-type="image/jpeg"/>
      <homepage-image
        class="brand-logo"
        :class="brand.id"
        width="100%"
        height="auto"
        :alt="`Logo for ${brand.name}`"
        :webp-source="`brands/logos/${brand.id}.webp`"
        :source="`brands/logos/${brand.id}.png`"
        source-type="image/png"/>
      <div
        v-if="brand.tag"
        class="tag">
        <homepage-text
          styling-variant="heading6"
          class="text-start">
          {{ brand.tag }}
        </homepage-text>
      </div>
    </plain-button>
    <div
      v-if="!expanded"
      class="expand-container">
      <plain-button
        aria-label="Expand section"
        type="button"
        class="expand-button"
        @click="expanded = true">
        <svg-chevron-down/>
      </plain-button>
    </div>
  </content-row>
</template>

<script>
import ContentRow from '@/homepage/components/common/ContentRow.vue'
import HomepageImage from '@/homepage/components/common/HomepageImage.vue'
import HomepageText from '@/homepage/components/common/HomepageText.vue'
import PlainButton from '@/homepage/components/common/PlainButton.vue'
import SvgChevronDown from '@/homepage/components/common/SvgChevronDown.vue'

export default {
  components: {
    ContentRow,
    HomepageImage,
    PlainButton,
    HomepageText,
    SvgChevronDown
  },
  data () {
    return {
      expanded: false,
      highlightedBrands: [
        {
          id: 'yumi_kim',
          name: 'Yumi Kim',
          tag: 'WOC-owned brand',
          brandLink: 'yumi-kim/YRmV85zAZr'
        },
        {
          id: 'joie',
          name: 'Joie',
          brandLink: 'joie/RQDVpvdNOY'
        },
        {
          id: 'paige',
          name: 'Paige',
          tag: 'Woman-owned brand',
          brandLink: 'paige/a9yVb2eVmw'
        },
        {
          id: 'boden',
          name: 'Boden',
          brandLink: 'boden/lgKVlymAR9'
        },
        {
          id: 'lilly_pulitzer',
          name: 'Lilly Pulitzer',
          brandLink: 'lilly-pulitzer/RQDVpyWAOY'
        },
        {
          id: 'of_mercer',
          name: 'Of Mercer',
          tag: 'Woman-owned brand',
          brandLink: 'of-mercer/9nrj21pN5w'
        },
        {
          id: 'french_connection',
          name: 'French Connection',
          brandLink: 'french-connection/Ymyj4RbAQx'
        },
        {
          id: 'bb_dakota',
          name: 'BB Dakota',
          brandLink: 'bb-dakota/rMGj5M5jZ4'
        },
        {
          id: 'oula',
          name: 'The Oula Company',
          tag: 'WOC-owned brand',
          brandLink: 'oula/GB1jQyvjP2'
        },
        {
          id: 'scotch_soda',
          name: 'Scotch & Soda',
          brandLink: 'scotch-and-soda/XzpNDK2Ax1'
        },
        {
          id: 'nicole_miller',
          name: 'Nicole Miller',
          brandLink: 'nicole-miller-ny/rGbVMGJNPD'
        },
        {
          id: 'equipment',
          name: 'Equipment',
          brandLink: 'equipment/0zvNYzQj86'
        },
        {
          id: 'zuri',
          name: 'Zuri',
          tag: 'WOC-owned brand',
          brandLink: 'zuri/rGbVMrbAPD'
        },
        {
          id: 'gravitas',
          name: 'Gravitas',
          tag: 'WOC-owned brand',
          brandLink: 'gravitas/076NaXvAXL'
        },
        {
          id: 'adrianna_papell',
          name: 'Adrianna Papell',
          brandLink: 'adrianna-papell/rGbVMv9APD'
        },
        {
          id: 'diane_von_furstenberg',
          name: 'Diane Von Furstenberg',
          tag: 'Woman-owned brand',
          brandLink: 'diane-von-furstenberg/76EjKnENm8'
        },
        {
          id: 'rag_bone',
          name: 'Rag & Bone',
          brandLink: 'rag-and-bone/rQnNkYyVRy'
        },
        {
          id: 'cupcakes_and_cashmere',
          name: 'Cupcakes and Cashmere',
          brandLink: 'cupcakes-and-cashmere/ykzA1eKAwa'
        },
        {
          id: 'tart',
          name: 'Tart Collections',
          brandLink: 'tart/8bkNOLBjJ1'
        },
        {
          id: 'brass',
          name: 'Brass',
          tag: 'Woman-owned brand',
          brandLink: 'brass/aoOAyXXVGl'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.brand-grid {
  width: 100%;
  margin-bottom: 28px;
  flex-grow: 1;
  padding-top: 12px;
  max-width: 1040px;
  max-height: 700px;
  overflow: hidden;
  position: relative;

  @include transition-base-all;

  &.expanded {
    max-height: 2600px;
  }
}

.expand-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 160px;
  z-index: 3;
  background: linear-gradient(0deg, $plum 35%, rgba($plum, 0) 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .expand-button {
    color: $white;
    width: 36px;
    height: 36px;
    margin: 0 auto;
    border: .5px solid $white;
    border-radius: $system-border-radius;
  }

}

.brand-button {
  width: 45%;
  aspect-ratio: 1;

  @include small {
    width: 30%;
  }

  @include medium {
    width: 24%;
  }

  margin-bottom: 4px;
  position: relative;
  background-color: $plum;

  .brand-member {
    opacity: .9;
    @include transition-base-all;
  }

  &:hover {
    .brand-member {
      opacity: .2;
    }
  }
}

.tag {
  padding: 2px;
  background-color: $white;
  position: absolute;
  left: 4px;
  bottom: 24px;
  width: fit-content;
  max-width: 120px;
}

.brand-logo {
  position: absolute;
  left: 2%;
  right: 2%;
  z-index: 3;
  top: 56%;
  margin-top: -25%;
  background: radial-gradient(50% 50% at 50% 50%, rgba($armor, .5) 31.3%, rgba($armor, 0) 100%);

  &.boden {
    margin-top: -35%;
  }
}
</style>
