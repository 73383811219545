<template>
  <div class="row bg-origami">
    <HomepageSection
      class="text-center professional-styling"
      title="Named Best Professional Styling"
      :paragraph="paragraphText">
      <template #between-headline-and-content>
        <img
          class="mb-md"
          width="222"
          height="33"
          :src="getImageUrl('logos/cosmo.svg')">
        <div
          v-if="isTabletOrSmaller"
          class="mb-md"
          :style="`background-image: url('${imageUrl}'); width: 100%; min-height: 224px; background-size: cover; position: relative;`"/>
        <h5>Need a little extra guidance?</h5>
      </template>
    </HomepageSection>
  </div>
</template>
<script setup>
import { getImageUrl } from '@/homepage/utils/url.js'
import useScreenSize from '@shared/composables/screenSize.js'
import HomepageSection from './HomepageSection.vue'

const { isTabletOrSmaller } = useScreenSize()
const imageUrl = getImageUrl('personal styling.jpg')
const paragraphText = "Let our human stylists do the heavy lifting to find the clothes you'll love. You'll be able to reach out for personalized, expert recommendations tailored to your style."
</script>

<style scoped lang="scss">

:deep(.professional-styling) {
  padding: 0;
  p {
    max-width: 75%;
  }
  @include media-tablet-or-smaller {
    p {
      max-width: 100%;
      padding: 0 1rem;
    }
  }
}
</style>
