<template>
  <div
    class="product-scroll">
    <div
      v-if="!isMobile"
      :style="cssVars"
      class="scroll-wrapper">
      <button
        ref="scroll-left"
        class="scroll scroll-left"
        @click="handleClick('left')">
        <svg-chevron-left/>
      </button>
    </div>

    <div
      ref="horizontal-scroll"
      class="horizontal-scroll">
      <div
        v-for="(sc, idx) in styleColors"
        :key="sc.id"
        :class="{ 'first': idx === 0}"
        class="style-card">
        <a :href="detailPageUrl(sc)">
          <img
            v-if="inViewport.now"
            class="style-image"
            :style="cssVars"
            :src="sc.images.front.url.replace('_d', '_m')">
        </a>
        <div class="style-color-title">
          {{ sc.style.brand }} {{ sc.style.subcategory }}
        </div>
        <div class="style-color-price">
          ${{ sc.style.retailPrice }} value
        </div>
      </div>
    </div>

    <div
      v-if="!isMobile"
      :style="cssVars"
      class="scroll-wrapper">
      <button
        ref="scroll-right"
        class="scroll scroll-right"
        @click="handleClick('right')">
        <svg-chevron-right/>
      </button>
    </div>
  </div>
</template>

<script>
import SvgChevronLeft from '@/components/global/svg/SvgChevronLeft'
import SvgChevronRight from '@/components/global/svg/SvgChevronRight'
import inViewport from 'vue-in-viewport-mixin'
import { slugify } from '@/utils/stringParsing.js'

export default {
  name: 'ProductScroll',
  components: {
    SvgChevronLeft,
    SvgChevronRight
  },
  mixins: [inViewport],
  props: {
    styleColors: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    cssVars () {
      return {
        '--photo-size': `${this.isMobile ? 160 : 204}px`
      }
    }
  },
  methods: {
    handleClick (direction) {
      const magnitude = 800
      this.$refs['horizontal-scroll'].scrollLeft += magnitude * (direction === 'right' ? 1 : -1)
      this.$refs['scroll-' + direction].blur()
    },
    detailPageUrl (styleColor) {
      const sluggedStyleName = slugify(`${styleColor.style.brand} ${styleColor.style.name}`)
      return `/closet/detail/${sluggedStyleName}/${styleColor.id}`
    }
  }
}
</script>

<style lang="scss" scoped>
.product-scroll {
  display: flex;
  justify-content: flex-start;
}

.horizontal-scroll {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  scroll-behavior: smooth;
  scroll-snap-type: x;

  &::-webkit-scrollbar {
    display: none;
  }
}

.style-card {
  text-align: left;
  scroll-snap-align: start;
  font-family: $body-font-family;

  &.first {
    padding-left: 24px;

    @include medium {
      padding-left: 0;
    }
  }
}

.style-image {
  height: var(--photo-size);
  min-width: calc(var(--photo-size)*.6);
  margin-right: 1em;
  border: 1px solid lightgrey;
}

.style-color-title {
  margin-top: .6em;
  font-weight: $font-weight-semibold;
  font-size: 0.85em;
  line-height: 1.2em;
  margin-right: 1em;
}

.style-color-price {
  margin-top: .3em;
  font-size: small;
  color: $pewter;
}

.scroll-wrapper {
  display: flex;
  align-items: center;
  height: var(--photo-size);
}

.scroll {
  background-color: white;
  color: $pewter;
  padding: 4px;
  border: 1px solid lightgrey;
  outline: none;
  height: fit-content;
  position: relative;
  cursor: pointer;
  transition: all 300ms ease;
  &.scroll-left {
    margin-right: 12px;
  }
  &.scroll-right {
    margin-left: 12px;
  }
  &:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba($ash, 0.5);
    box-shadow: 0 0 0 0.2rem rgba($ash, 0.5);
  }
}
</style>
