<template>
  <div class="row bg-armor">
    <div
      class="col-12 col-lg-6 ps-0 pe-0 pe-lg-2 header-image"
      :style="{ backgroundImage: `url('${getImageUrl(`referral/referral-desktop-alt.jpg`)}')` }"/>
    <div class="col-12 col-lg-6 pt-lg-md pe-lg-xxl ps-md pb-lg">
      <h2
        class="text-white section-headline"
        :class="{ 'show': client && referralCampaign }">
        {{ headerText(client && client.firstName, referralCampaign) }}
      </h2>
      <h4 class="text-white">
        {{ subheaderText }}
      </h4>
      <p class="text-white">
        {{ bodyText }}
      </p>
      <GetStartedButton
        v-if="isDesktop"
        class="mt-md"
        location="Refer Page"/>
    </div>
  </div>
</template>

<script setup>
import { headerText, subheaderText, bodyText } from '@/homepage/utils/referrals.js'
import { getImageUrl } from '@/homepage/utils/url.js'
import GetStartedButton from '@/homepage/components/GetStartedButton.vue'
import useScreenSize from '@shared/composables/screenSize.js'

const { isDesktop } = useScreenSize()

defineProps({
  client: {
    type: Object,
    default: null
  },
  referralCampaign: {
    type: Object,
    default: null
  }
})
</script>
<style lang="scss" scoped>
.header-image {
    background-size: cover;
    background-position: top;
    @include media-tablet-or-smaller {
    height: 100%;
    aspect-ratio: 3/1;
    }
}
</style>
