<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 46 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <title>
      Decorative quote indicator
    </title>
    <path
      d="M15.3333 15.1529C16.5128 15.9059 17.4302 16.9098 18.0855 18.1647C18.8063 19.3569 19.1667 20.7059 19.1667 22.2118C19.1667 25.098 18.1838 27.451 16.2179 29.2706C14.2521 31.0902 11.8276 32 8.94444 32C6.32336 32 4.16097 31.2157 2.45726 29.6471C0.819088 28.0784 0 26.0392 0 23.5294C0 22.2118 0.229337 20.9569 0.688026 19.7647C1.21224 18.5725 2.09687 17.0353 3.34188 15.1529L13.1709 0H22.8034L15.3333 15.1529ZM38.5299 15.1529C39.7094 15.9059 40.6268 16.9098 41.282 18.1647C42.0028 19.3569 42.3632 20.7059 42.3632 22.2118C42.3632 25.098 41.3803 27.451 39.4145 29.2706C37.4487 31.0902 35.0242 32 32.141 32C29.5199 32 27.3575 31.2157 25.6538 29.6471C24.0157 28.0784 23.1966 26.0392 23.1966 23.5294C23.1966 22.2118 23.4259 20.9569 23.8846 19.7647C24.4088 18.5725 25.2934 17.0353 26.5385 15.1529L36.3675 0H46L38.5299 15.1529Z"
      class="quote"/>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 40
    }
  }
}
</script>

<style lang="scss" scoped>
.quote {
  fill: #E2DADF
}
</style>
