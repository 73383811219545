import { defineStore } from 'pinia'
import { ref } from 'vue'
import { getTopBrands } from '@shared/api/brands.js'

export const useBrandsStore = defineStore('brands', () => {
  const topBrands = ref([])

  const priorityMap = new Map([
    ['Paige', 0],
    ['Equipment', 1],
    ['Lilly Pulitzer', 2],
    ['Milly', 3],
    ['Yumi Kim', 4]
  ])

  async function fetchTopBrands () {
    const { data } = await getTopBrands()
    topBrands.value = sortBrands(data)
  }

  function sortBrands (brands) {
    return brands.toSorted((a, b) => {
      const aPriority = priorityMap.get(a.name) ?? Infinity
      const bPriority = priorityMap.get(b.name) ?? Infinity
      return aPriority - bPriority || a.name.localeCompare(b.name)
    })
  }

  return {
    topBrands,
    fetchTopBrands
  }
})
