<template>
  <div class="row">
    <HomepageSection
      title="Find Your New Favorite Brands"
      :paragraph="paragraphText"/>
    <TopBrandsHorizontalScroll
      class="mb-xl"/>
  </div>
</template>
<script setup>
import TopBrandsHorizontalScroll from '@shared/components/ADORN/TopBrandsHorizontalScroll.vue'
import HomepageSection from './HomepageSection.vue'

const paragraphText = 'Explore hundreds of brands we stocked with you in mind.'
</script>
