<template>
  <brands-list
    id="brands-sustainability"
    :brand-images="brands"
    show-all-on-mobile
    title="We are proud to carry sustainable brands."
    subtitle="Our inventory features local, female-owned designers, and clothiers who are gentle on the earth and provide fair wages to artisans across the globe."/>
</template>

<script>
import BrandsList from './BrandsList'

export default {
  name: 'SustainableBrands',
  components: {
    BrandsList
  },
  data () {
    return {
      brands: [
        {
          alt: 'Boden',
          url: 'sustainability/boden-small.png'
        },
        {
          alt: 'Brass',
          url: 'sustainability/brass-small.png'
        },
        {
          alt: 'Eileen Fisher',
          url: 'sustainability/eileen-fisher-small.png'
        },
        {
          alt: 'Kirrin Finch',
          url: 'sustainability/kirrin-finch.png'
        },
        {
          alt: 'Lacausa',
          url: 'how-it-works/brand-logos/lacausa.png'
        },
        {
          alt: 'Levis',
          url: 'sustainability/levis.png'
        },
        {
          alt: 'Oula',
          url: 'sustainability/oula.png'
        },
        {
          alt: 'Prairie Underground',
          url: 'sustainability/prairie-underground.png'
        },
        {
          alt: 'Prima Dona',
          url: 'sustainability/prima-dona.png'
        },
        {
          alt: 'Rec Room',
          url: 'sustainability/rec-room.png'
        },
        {
          alt: 'Reformation',
          url: 'sustainability/reformation.png'
        },
        {
          alt: 'Zeraffa',
          url: 'sustainability/zeraffa.png'
        },
        {
          alt: 'Zuri',
          url: 'how-it-works/brand-logos/zuri.png'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
#brands-sustainability {
  background-color: $white;

  .brand-logo {
    @include medium {
      height: 132px;
      line-height: 72px;
      padding: 40px 48px;
    }

    img {
      @include medium {
        max-height: 72px;
        max-width: 11vw;
      }
    }

  }
}
</style>
