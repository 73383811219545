<template>
  <div
    class="renting-with-armoire">
    <div class="title-wrapper">
      <div>
        <h6 class="header">
          Why you'll love
        </h6>
        <h2 class="sub-header">
          Renting with Armoire
        </h2>
      </div>
    </div>
    <div
      class="features-wrapper">
      <div
        ref="features"
        class="features">
        <div
          v-for="(feature, index) in features"
          :key="'feature-' + index"
          class="feature">
          <img
            class="feature-icon"
            height="72"
            :src="getImageUrl(`referral/icon-feature-${index + 1}.svg`)">
          <p class="feature-title">
            {{ feature.title }}
          </p>
          <p>
            {{ feature.description }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="isMobile"
      class="scroll-indicators">
      <div
        v-for="i in features.length"
        :key="'indicator-' + i"
        class="scroll-indicator"
        :class="{'active': currentIndex + 1 === i}"/>
    </div>
  </div>
</template>

<script>
import { getImageUrl } from '@/homepage/utils/url.js'

export default {
  name: 'RentingWithArmoire',
  data () {
    return {
      currentIndex: 0,
      features: [
        {
          title: 'Access thousands of high end styles',
          description: 'We carry hundreds of brands in sizes 0 - 24'
        },
        {
          title: 'Keep your closet clear',
          description: 'Don\'t waste money and closet space on clothes you\'ll only wear a few times'
        },
        {
          title: 'Full-service styling',
          description: 'Request a stylist closet or have a stylist pick a case for you'
        },
        {
          title: 'Free & fast shipping, both ways',
          description: 'Use your reusable bag and included shipping label for easy returns'
        },
        {
          title: 'Cleaning and minor spills are covered',
          description: 'Say goodbye to spending time (and money!) at the dry cleaners'
        },
        {
          title: 'Flexible returns -- never be without clothes',
          description: 'Keep your old items until new items arrive -- no gaps!'
        }
      ]
    }
  },
  mounted () {
    this.$refs.features.onscroll = ({ target: { scrollLeft } }) => {
      this.currentIndex = Math.round(scrollLeft / (this.$refs.features.scrollWidth / this.features.length))
    }
  },
  methods: {
    getImageUrl
  }
}
</script>

<style lang="scss" scoped>
  .renting-with-armoire {
    padding: 24px 0;
  }

  .title-wrapper {
    display: flex;
    justify-content: center;
  }

  .header {
    margin-bottom: 0;
    font-size: 1.2em;
  }

  .sub-header {
    margin-top: 12px;
  }

  .features-wrapper {
    display: flex;
    justify-content: center;
  }

  .features {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }

    @include medium {
      flex-wrap: wrap;
      max-width: 1200px;
    }

  }

  .feature {
    flex: 1 0 100%;
    scroll-snap-align: start;
    padding: 12px 24px;

    @include medium {
      flex: 1 0 33%;
    }

  }

  .feature-title {
    font-weight: $font-weight-bold;
  }

  .scroll-indicators {
    display: flex;
    justify-content: center;
  }

  .scroll-indicator {
    height: 8px;
    width: 8px;
    margin: 0 4px;
    border-radius: 100%;
    background-color: $ash;
    &.active {
      background-color: $pewter;
    }
  }
</style>
