<template>
  <homepage-section
    variant="white"
    :class="{'px-0': showVariant}">
    <content-stack>
      <content-wrapper :class="{'fullWidth': showVariant}">
        <content-stack :class="{'fullWidth': showVariant}">
          <content-wrapper>
            <HomepageRedesignExperiment>
              <template #variantRedesign>
                <h2>Armoire vs. Other clothing rental subscriptions</h2>
              </template>
              <template #default>
                <homepage-text
                  tag="h2"
                  styling-variant="heading1">
                  Armoire vs. Other clothing rental subscriptions
                </homepage-text>
              </template>
            </HomepageRedesignExperiment>
          </content-wrapper>
          <div :class="showVariant ? 'variant-table-container' : 'table-container'">
            <table class="comparison-table">
              <thead>
                <tr>
                  <th>
                    <HomepageRedesignExperiment>
                      <template #variantRedesign>
                        <component
                          :is="isMobile ? 'h5' : 'h3'">
                          Features
                        </component>
                      </template>
                      <template #default>
                        <homepage-text
                          class="text-start"
                          styling-variant="heading2">
                          Features
                        </homepage-text>
                      </template>
                    </HomepageRedesignExperiment>
                  </th>
                  <th class="company">
                    <HomepageRedesignExperiment>
                      <template #variantRedesign>
                        <component
                          :is="isMobile ? 'h5' : 'h3'"
                          class="text-center">
                          Armoire
                        </component>
                      </template>
                      <template #default>
                        <homepage-text
                          class="company-header text-center"
                          styling-variant="heading3">
                          Armoire
                        </homepage-text>
                      </template>
                    </HomepageRedesignExperiment>
                  </th>
                  <th class="company">
                    <HomepageRedesignExperiment>
                      <template #variantRedesign>
                        <component
                          :is="isMobile ? 'h5' : 'h3'"
                          class="text-center">
                          Rent the Runway
                        </component>
                      </template>
                      <template #default>
                        <homepage-text
                          class="company-header text-center"
                          styling-variant="heading3">
                          Rent the Runway
                        </homepage-text>
                      </template>
                    </HomepageRedesignExperiment>
                  </th>
                  <th class="company">
                    <HomepageRedesignExperiment>
                      <template #variantRedesign>
                        <component
                          :is="isMobile ? 'h5' : 'h3'"
                          class="text-center">
                          Nuuly
                        </component>
                      </template>
                      <template #default>
                        <homepage-text
                          class="company-header text-center"
                          styling-variant="heading3">
                          Nuuly
                        </homepage-text>
                      </template>
                    </HomepageRedesignExperiment>
                  </th>
                </tr>
              </thead>
              <tbody class="comparison-body">
                <tr
                  v-for="attribute in attributes"
                  :key="attribute.id">
                  <td>
                    <homepage-text
                      class="text-start feature"
                      styling-variant="copy-small">
                      {{ attribute.text }}
                    </homepage-text>
                  </td>
                  <td>
                    <svg-check
                      v-if="attribute.armoire"
                      class="svg"
                      :height="isMobile ? 24 : 32"
                      :width="isMobile ? 24 : 32"
                      :stroke-width="2.5"
                      variant="success"/>
                  </td>
                  <td>
                    <svg-check
                      v-if="attribute.rtr"
                      class="svg"
                      :height="isMobile ? 24 : 32"
                      :width="isMobile ? 24 : 32"
                      :stroke-width="2.5"
                      variant="success"/>
                    <svg-x
                      v-else
                      class="svg"
                      :height="isMobile ? 24 : 32"
                      :width="isMobile ? 24 : 32"/>
                  </td>
                  <td>
                    <svg-check
                      v-if="attribute.nuuly"
                      class="svg"
                      :height="isMobile ? 24 : 32"
                      :width="isMobile ? 24 : 32"
                      :stroke-width="2.5"
                      variant="success"/>
                    <svg-x
                      v-else
                      class="svg"
                      :height="isMobile ? 24 : 32"
                      :width="isMobile ? 24 : 32"/>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <homepage-get-started-lockup location="Comparison">
            Take your Style Quiz today and experience the best of clothing rental
          </homepage-get-started-lockup>
        </content-stack>
      </content-wrapper>
      <decorative-divider
        v-if="!showVariant"
        variant="bottom"/>
    </content-stack>
  </homepage-section>
</template>

<script>
import ContentStack from '@/homepage/components/common/ContentStack.vue'
import ContentWrapper from '@/homepage/components/common/ContentWrapper.vue'
import DecorativeDivider from '@/homepage/components/common/DecorativeDivider.vue'
import HomepageText from '@/homepage/components/common/HomepageText.vue'
import HomepageSection from '@/homepage/components/common/HomepageSection.vue'
import HomepageGetStartedLockup from '@/homepage/components/common/HomepageGetStartedLockup.vue'
// TODO: [ENG-4085] Consolidate these SVGs
import SvgCheck from '@/homepage/components/common/SvgCheck.vue'
import SvgX from '@/homepage/components/common/SvgX.vue'
import HomepageRedesignExperiment from '@shared/components/HomepageRedesignExperiment.vue'
import { useHomepageRedesignExperiment } from '@shared/composables/experiment.js'

export default {
  components: {
    ContentStack,
    ContentWrapper,
    DecorativeDivider,
    HomepageText,
    HomepageSection,
    HomepageGetStartedLockup,
    SvgCheck,
    SvgX,
    HomepageRedesignExperiment
  },
  setup () {
    const { showVariant } = useHomepageRedesignExperiment()
    return {
      showVariant
    }
  },
  data () {
    return {
      attributes: [
        {
          id: 'curated',
          text: 'Curated closet via recommendation algorithm and personal stylists',
          armoire: true,
          rtr: false,
          nuuly: false
        },
        {
          id: 'flexible',
          text: 'Flexible plans for every budget, including an Unlimited plan',
          armoire: true,
          rtr: false,
          nuuly: false
        },
        {
          id: 'high-end',
          text: 'High-end brands you love',
          armoire: true,
          rtr: true,
          nuuly: true
        },
        {
          id: 'women',
          text: 'Rising women- and POC-owned brands you should know about',
          armoire: true,
          rtr: false,
          nuuly: false
        },
        {
          id: 'returns',
          text: 'Flexible returns — keep your old items until new items arrive',
          armoire: true,
          rtr: false,
          nuuly: false
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

.fullWidth {
  width: 100%;
  --vertical-margin: 0px;
}

.table-container {
  border-top: .5px solid $velvet;
  padding-top: 24px;
}

.variant-table-container {
  border-top: .5px solid $pewter;
  padding-top: 24px;
  width: 100%;
}

.feature {
  min-width: 128px;
  @include media-tablet-or-smaller {
    font-size: 14px !important;    min-width: 96px;
  }
}

.comparison-table {
  border-collapse: collapse;

  th {
    padding: 4px;

    @include medium {
      padding: 12px;
    }

    &.company {
      padding: 8px 6px;

      @include medium {
        width: 16%;
        padding: 16px 0;
      }

      .company-header {
        font-size: $font-size-base * .6;
        line-height: $font-size-base * .8;

        @include medium {
          font-size: unset;
          line-height: unset;
        }
      }
    }
  }

  .comparison-body {
    tr:nth-child(even) {background: $white}
    tr:nth-child(odd) {background: $origami}

    td {
      max-width: 560px;
      vertical-align: middle;
      text-align: center;
      padding: 16px 9px;
      width: fit-content;
      @include medium {
        padding: 16px 20px;
      }

    }
  }
}

.svg {
  margin-top: 4px;
}

</style>
