<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <title>Checkmark enclosed in a circle</title>
    <circle
      class="circle"
      :class="variant"
      cx="10"
      cy="10.5"
      r="10"/>
    <path
      class="check"
      :class="variant"
      d="M5 10.9446L7.92135 13.8335L15 6.8335"
      :stroke-width="strokeWidth"
      stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: 'SvgCheck',
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator: value => {
        return value.match(/(primary|secondary|inverse|success)/)
      }
    },
    size: {
      type: Number,
      default: 24
    },
    strokeWidth: {
      type: Number,
      default: 2
    }
  }
}
</script>

<style lang="scss" scoped>
  .circle {
    fill: rgba($champagne, 0.8);

    &.inverse {
      fill: $white;
    }

    &.secondary {
      fill: $plum;
      stroke: $white;
      stroke-width: .5px;
    }

    &.success {
      fill: $success-dark;
    }
  }

  .check {
    stroke: $armor;

    &.inverse {
      stroke: $plum;
    }

    &.secondary {
      stroke: $white;
    }

    &.success {
      stroke: $white;
    }
  }
</style>
