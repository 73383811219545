// Helper methods to add and remove the robots meta tag to disable search indexing.
// Once we've updated to more modern packages, switch to @unhead/vue library.

export function getNoIndexTag () {
  return document.querySelector('meta[name="robots"][content="noindex"]')
}

export function addNoIndexTag () {
  if (!getNoIndexTag()) {
    const meta = document.createElement('meta')
    meta.name = 'robots'
    meta.content = 'noindex'
    document.head.appendChild(meta)
  }
}

export function removeNoIndexTag () {
  const robotsMetaTag = getNoIndexTag()
  if (robotsMetaTag) {
    robotsMetaTag.remove()
  }
}
