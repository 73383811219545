<template>
  <div class="banner-content">
    <progressive-image
      v-if="isMobile && showPhotoOnMobile"
      :src="getImageUrl(mobileImageUrl)"
      :placeholder="getImageUrl(placeholderImageUrl)"
      class="banner-image"
      :class="{ 'image-right-small': imageRight }"/>
    <progressive-image
      v-if="!isMobile"
      :src="getImageUrl(imageUrl)"
      :placeholder="getImageUrl(placeholderImageUrl)"
      class="banner-image"
      :class="{ 'image-right-small': imageRight }"/>
    <div class="content-items">
      <slot/>
    </div>
    <progressive-image
      v-if="imageRight"
      :src="getImageUrl(imageUrl)"
      :placeholder="getImageUrl(placeholderImageUrl)"
      class="banner-image image-right-medium"/>
  </div>
</template>

<script>
import { getImageUrl } from '@/homepage/utils/url.js'

export default {
  name: 'ImageHalfSection',
  props: {
    showPhotoOnMobile: {
      type: Boolean,
      default: true
    },
    imageUrl: {
      type: String,
      default: ''
    },
    mobileImageUrl: {
      type: String,
      default: ''
    },
    placeholderImageUrl: {
      type: String,
      default: ''
    },
    imageRight: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getImageUrl
  }
}
</script>

<style lang="scss" scoped>
.banner-content {
    display: flex;
    background-color: $background;
    flex-wrap: wrap;

    @include medium {
      height: fit-content;
    }

    .banner-image {
        height: 252px;
        width: 100%;

        // Adjust progressive image element to use cover styling
        :global(.progressive-image-main) {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center top;
        }

        @include medium {
            height: auto;
            width: 50%;
        }

        &:before {
          height: 100%;
          background: rgba(0, 0, 0, 0.2); // black 20%
        }

        // If imageRight = true is passed, add these
        // to decide what to hide / show when
        &.image-right-medium {
            display: none;

            @include medium {
                display: block;
            }
        }
        &.image-right-small {
            display: block;
            @include medium {
                display: none;
            }
        }
    }

    .content-items {
        padding: 44px;
        width: 100%;
        text-align: left;

        @include medium {
          width: 50%;
          padding: 6vw 5%;
        }
    }
}

</style>
