<template>
  <homepage-section
    :use-default-padding="false"
    class="real-members">
    <content-wrapper :max-width="900">
      <content-stack class="content-container">
        <content-wrapper :max-width="686">
          <homepage-text
            tag="h2"
            class="text-center text-white"
            styling-variant="heading1">
            Real members, <br v-if="isMobile">Real style
          </homepage-text>
        </content-wrapper>
        <div class="member-container">
          <div
            class="member-scroll"
            :class="{'border-top' : !isMobile}">
            <plain-button
              aria-label="Scroll to previous member"
              class="scroll-button left"
              @click="updateMemberIndex(-1)">
              <svg-chevron-left/>
            </plain-button>
            <plain-button
              class="scroll-button right"
              aria-label="Scroll to next member"
              @click="updateMemberIndex(1)">
              <svg-chevron-right/>
            </plain-button>
          </div>
          <transition
            name="fade"
            mode="out-in">
            <content-stack
              :key="memberInFocus.id"
              :spacing="12">
              <content-row>
                <content-stack
                  class="member-info"
                  :spacing="2">
                  <homepage-text
                    tag="h3"
                    styling-variant="heading3"
                    class="text-white text-center">
                    {{ memberInFocus.name }}
                  </homepage-text>
                  <component
                    :is="isMobile ? 'content-stack' : 'content-row'"
                    :spacing="isMobile ? 0 : 12 ">
                    <homepage-text
                      tag="p"
                      class="text-white"
                      styling-variant="quote-small">
                      {{ memberInFocus.title }}
                    </homepage-text>
                    <decorative-divider
                      v-if="!isMobile"
                      :height="28"
                      variant="vertical"/>
                    <homepage-text
                      tag="p"
                      class="text-white"
                      styling-variant="quote-small">
                      Member since {{ memberInFocus.memberSince }}
                    </homepage-text>
                  </component>
                </content-stack>
              </content-row>
              <content-row
                v-if="inViewport.now"
                dot-variant="white"
                :length="3"
                class="member-images">
                <progressive-image
                  class="member-image image-1"
                  :alt="`${memberInFocus.name} wearing Armoire clothing`"
                  :src="getImageUrl(`realmembers/${memberInFocus.id}/1.webp`)"
                  :fallback-src="getImageUrl(`realmembers/${memberInFocus.id}/1.jpg`)"/>
                <progressive-image
                  class="member-image"
                  :alt="`${memberInFocus.name} wearing Armoire clothing`"
                  :src="getImageUrl(`realmembers/${memberInFocus.id}/2.webp`)"
                  :fallback-src="getImageUrl(`realmembers/${memberInFocus.id}/2.jpg`)"/>
                <progressive-image
                  class="member-image image-3"
                  :alt="`${memberInFocus.name} wearing Armoire clothing`"
                  :src="getImageUrl(`realmembers/${memberInFocus.id}/3.webp`)"
                  :fallback-src="getImageUrl(`realmembers/${memberInFocus.id}/3.jpg`)"/>
              </content-row>
              <component
                :is="isMobile ? 'content-stack' : 'content-row'"
                class="member-stats"
                :spacing="isMobile ? 8 : 0">
                <content-stack
                  class="member-stat"
                  :spacing="2">
                  <homepage-text
                    tag="h4"
                    styling-variant="heading5"
                    class="text-white text-center">
                    Favorite Brand
                  </homepage-text>
                  <homepage-text
                    tag="p"
                    styling-variant="heading5"
                    class="text-white text-center normal">
                    {{ memberInFocus.topBrand }}
                  </homepage-text>
                </content-stack>
                <decorative-divider
                  v-if="!isMobile"
                  :height="40"
                  variant="vertical"/>
                <content-stack
                  class="member-stat"
                  :spacing="0">
                  <homepage-text
                    tag="h4"
                    styling-variant="heading5"
                    class="text-white text-center">
                    Plan
                  </homepage-text>
                  <homepage-text
                    tag="p"
                    styling-variant="heading5"
                    class="text-white text-center normal">
                    {{ memberInFocus.plan }}
                  </homepage-text>
                </content-stack>
                <decorative-divider
                  v-if="!isMobile"
                  :height="40"
                  variant="vertical"/>
                <content-stack
                  class="member-stat"
                  :spacing="0">
                  <homepage-text
                    tag="h4"
                    styling-variant="heading5"
                    class="text-white text-center">
                    Value rented
                  </homepage-text>
                  <homepage-text
                    tag="p"
                    styling-variant="heading5"
                    class="text-white text-center normal">
                    ${{ memberInFocus.valueRented }}
                  </homepage-text>
                </content-stack>
              </component>
              <content-wrapper
                class="member-quote"
                :max-width="800">
                <homepage-text
                  tag="p"
                  class="text-white"
                  styling-variant="quote-small">
                  {{ memberInFocus.quote }}
                </homepage-text>
              </content-wrapper>
              <marketing-button
                type="a"
                :href="`/community/profile/${memberInFocus.profileId}/rental-history/?homepage-visitor=true`"
                :event-label="`Real Members - ${memberInFocus.name} Rental History`"
                variant="tertiary">
                See what {{ memberInFocus.name }} has rented
              </marketing-button>
            </content-stack>
          </transition>
        </div>
      </content-stack>
    </content-wrapper>
  </homepage-section>
</template>

<script>
import ContentStack from '@/homepage/components/common/ContentStack.vue'
import ContentRow from '@/homepage/components/common/ContentRow.vue'
import ContentWrapper from '@/homepage/components/common/ContentWrapper.vue'
import HomepageSection from '@/homepage/components/common/HomepageSection.vue'
import HomepageText from '@/homepage/components/common/HomepageText.vue'
import MarketingButton from '@/homepage/components/common/MarketingButton.vue'
import DecorativeDivider from '@/homepage/components/common/DecorativeDivider.vue'
import HomepageImage from '@/homepage/components/common/HomepageImage.vue'
import PlainButton from '@/homepage/components/common/PlainButton.vue'
import SvgChevronLeft from '@/components/global/svg/SvgChevronLeft.vue'
import SvgChevronRight from '@/components/global/svg/SvgChevronRight.vue'

import inViewport from 'vue-in-viewport-mixin'
import { getImageUrl } from '@/homepage/utils/url.js'

export default {
  components: {
    ContentWrapper,
    ContentRow,
    HomepageImage,
    HomepageSection,
    HomepageText,
    ContentStack,
    MarketingButton,
    DecorativeDivider,
    PlainButton,
    SvgChevronLeft,
    SvgChevronRight
  },
  mixins: [inViewport],
  data () {
    return {
      memberIndex: 0,
      members: [
        {
          id: 'berea',
          name: 'Berea',
          title: 'Product and Technology Executive',
          memberSince: '2017',
          topBrand: 'Joie',
          plan: 'Armoire Unlimited',
          valueRented: '141,925',
          profileId: 'kErNndYV9e',
          quote: '“Armoire is a game changer! With my subscription I always have something great to wear — for work, vacations, or evenings out. Their high quality clothes make me feel amazing, and I save tons of time on shopping and laundry. I can’t imagine life without Armoire!”'
        },
        {
          id: 'erin',
          name: 'Erin',
          title: 'Director, Admissions & Recruitment',
          memberSince: '2020',
          topBrand: 'White + Warren',
          plan: '4-Item Plan',
          valueRented: '45,304',
          profileId: 'RmV8mGKDAZ',
          quote: '“I absolutely love getting to see these clothes on real people in the Power Feed —  I’ve taken chances on items I otherwise might not have tried, and I get to feel connected to a fun, supportive and hard working community of women who also happen to enjoy fashion.”'
        },
        {
          id: 'sherry',
          name: 'Sherry',
          title: 'Chief Actuary',
          memberSince: '2020',
          topBrand: 'French Connection',
          plan: '4-Item Plan',
          valueRented: '56,339',
          profileId: 'QDVpn1DWNO',
          quote: '"Armoire allows me to explore different styles easily without commitment! I get to explore different aspects of who I am through this rich fashion journey. I love the supportive community and Armorie\'s top-notch customer service!"'
        },
        {
          id: 'cheryl',
          name: 'Cheryl',
          title: 'Business Owner',
          memberSince: '2019',
          topBrand: 'BB Dakota',
          plan: '4-Item Plan',
          valueRented: '62,136',
          profileId: 'xdjrWwyVpY',
          quote: '“Armoire was a part of my weight loss journey. Be it when I was a size 8 or fluctuating between a size 4 or 2, I can freely choose my clothing be it for the summer or winter wear.”'
        },
        {
          id: 'rubie',
          name: 'Rubie',
          title: 'Grandma & Foodie',
          memberSince: '2017',
          topBrand: 'Frnech Connection',
          plan: 'Armoire Unlimited',
          valueRented: '81,927',
          profileId: '4KONx1Bj5Z',
          quote: '“Armoire has changed my outlook on clothes. I experiment all the time with new styles & have lots of fun doing it. Having something new to wear for every occasion is a game changer. The PWR feed is really helpful because you can look at reviews & pictures!”'
        }
      ]
    }
  },
  computed: {
    memberInFocus () {
      return this.members[this.memberIndex]
    }
  },
  methods: {
    getImageUrl,
    updateMemberIndex (value) {
      let newIndex = this.memberIndex + value
      if (newIndex === -1) newIndex = this.members.length - 1
      if (newIndex === this.members.length) newIndex = 0
      this.memberIndex = newIndex
    }
  }
}
</script>

<style lang="scss" scoped>
.real-members {
  padding: 60px 0;
}

.real-members, .content-container, .member-container {
  width: 100%;
}

.member-info, .member-quote {
  padding: 0 12px;
}

.member-scroll {
  width: 95%;
  padding-top: 12px;
  margin: 0 12px;
  position: relative;

  @include large {
    width: 100%;
    margin: 0 auto;
  }
}

.member-stats {
  @include medium {
    width: 100%;
    justify-content: space-between;
  }
}

.member-images {
  width: 100%;
  overflow-x: scroll;
  justify-content: flex-start;

  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
  -ms-overflow-style: none;  // IE 10+
  overflow: -moz-scrollbars-none;  // Firefox

  @include large {
    width: 100%;
    justify-content: space-between;
  }
}

.member-stat {
  margin-bottom: 16px;

  @include medium {
    width: 31%;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 0;
  }
}

.member-image {
  width: 80vw;
  height: 105vw;
  flex-shrink: 0;
  max-width: 320px;
  max-height: 444px;
  margin-bottom: 24px;
  margin-right: 8px;

  @include large {
    width: 31.5%;
    height: unset;
    min-height: 300px;
    margin-bottom: 0;
    max-height: 430px;
  }

  @include large {
    height: 376px;
  }
}

.image-1 {
  margin-left: 24px;

  @include large {
    margin-left: 0;
  }
}

.image-3 {
  margin-right: 24px;

  @include large {
    margin-right: 0;
  }
}

.scroll-button {
  position: absolute;
  top: 24px;;
  width: 36px;
  height: 36px;
  border: .5px solid $white;
  border-radius: $system-border-radius;
  color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include large {
    top: 44px;
  }

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
}
</style>
