<template>
  <HomepageRedesignExperiment>
    <template #variantRedesign>
      <PageWrapper title="Frequently Asked Questions">
        <FaqSwitcher class="col-10 offset-1 d-flex flex-column align-items-center mb-xxl"/>
        <div class="learn-more bg-origami text-center px-sm py-xxl">
          <h2>Still have questions?</h2>
          <p class="mt-sm mb-lg">
            See more information about our offerings
          </p>
          <BaseButton
            to="https://support.armoire.style/faq"
            text="Learn More"/>
        </div>
      </PageWrapper>
    </template>
    <template #default>
      <FaqPage/>
    </template>
  </HomepageRedesignExperiment>
</template>

<script setup>
import { onActivated } from 'vue'
import useAnalytics from '@shared/composables/analytics.js'
import FaqPage from '@/homepage/components/faq/FaqPage.vue'
import PageWrapper from '@/homepage/components/revamp/PageWrapper.vue'
import HomepageRedesignExperiment from '@shared/components/HomepageRedesignExperiment.vue'
import FaqSwitcher from '@/homepage/components/faq/FaqSwitcher.vue'
import BaseButton from '@shared/components/ADORN/BaseButton.vue'

const { trackPageView } = useAnalytics()
onActivated(() => {
  trackPageView('FAQ Page')
})
</script>
