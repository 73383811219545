<template>
  <component
    v-bind="$attrs"
    :is="type"
    :tag="tag"
    :to="to"
    :href="href"
    :target="type === 'a' ? '_blank' : '_self'"
    class="marketing-button"
    :class="`${variant} ${!!backgroundVariant ? `bg-${backgroundVariant}` : ''}`"
    @click="onClick">
    <slot/>
  </component>
</template>

<script setup>
import useAnalytics from '@shared/composables/analytics.js'

const props = defineProps({
  type: {
    type: String,
    default: 'button',
    validator: value => {
      return value.match(/(button|a|router-link)/)
    }
  },
  tag: {
    type: String,
    default: 'a',
    validator: value => {
      return value.match(/(button|a)/)
    }
  },
  to: {
    type: Object,
    default: null
  },
  href: {
    type: String,
    default: null
  },
  variant: {
    type: String,
    default: 'primary',
    validator: value => {
      return value.match(/(primary|secondary|tertiary)/)
    }
  },
  // Pass the type of background that this button is rendered
  // against, so that we can ensure accessible contrast of box
  // shadow in focused states. Otherwise, primary assumes light
  // background, secondary + tertiary assume dark background
  backgroundVariant: {
    type: String,
    default: null,
    validator: value => value.match(/(light|dark)/)
  },
  eventLabel: {
    type: String,
    default: null
  }
})

const emit = defineEmits({
  click: (event) => event instanceof MouseEvent
})

const { trackClickEvent } = useAnalytics()

async function onClick (event) {
  if (props.eventLabel) {
    await trackClickEvent('Homepage', props.eventLabel)
  }
  emit('click', event)
}
</script>

<style lang="scss" scoped>
.marketing-button {
  font-family: $buttons-font-family;
  font-size: $font-size-base * .875;
  line-height: $font-size-base * 1.25;
  font-weight: $font-weight-bold;
  letter-spacing: 0.2em;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  border-radius: $system-border-radius;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 24px;
  min-width: 295px;
  height: 44px;

  background-color: $orchid;
  color: $white;
   @include transition-base-all;

  &.secondary {
    border: 1px solid $orchid;
    background-color: $white;
    color: $orchid;
  }

  &.tertiary {
    background-color: rgba($white, 0);
    color: $white;
    border: 1px solid $white;
  }

  &:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba($pewter, 0.7);
    box-shadow: 0 0 0 0.2rem rgba($pewter, 0.7);

    &.tertiary, &.secondary, &.bg-dark {
      -webkit-box-shadow: 0 0 0 0.2rem rgba($origami, 0.7);
      box-shadow: 0 0 0 0.2rem rgba($origami, 0.7);
    }

    &.bg-light {
      -webkit-box-shadow: 0 0 0 0.2rem rgba($pewter, 0.7);
      box-shadow: 0 0 0 0.2rem rgba($pewter, 0.7);
    }
  }

  &:hover {
    background-color: lighten($orchid, 5%);

    &.secondary {
      background-color: $white;
      color: lighten($orchid, 5%);
      border: 1px solid lighten($orchid, 5%);
    }

    &.tertiary {
      background-color: rgba($white, .1);
      color: $white;
    }
  }
}
</style>
