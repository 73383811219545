<template>
  <homepage-section variant="white">
    <content-stack :spacing="24">
      <decorative-divider/>
      <content-wrapper :max-width="686">
        <homepage-text
          tag="h2"
          styling-variant="heading1">
          In the news
        </homepage-text>
      </content-wrapper>
      <content-wrapper>
        <component
          :is="isMobile ? 'snap-scroll' : 'content-row'"
          :length="newsQuotes.length"
          class="flex-wrap">
          <plain-button
            v-for="quote in newsQuotes"
            :key="quote.sourceName"
            type="a"
            :label="`Link to article on Armoire published by ${quote.sourceName}`"
            :href="quote.sourceUrl"
            class="news-source-button">
            <content-stack
              :spacing="2"
              class="justify-content-start">
              <svg-decorative-quote/>
              <homepage-image
                v-if="inViewport.now"
                class="news-source-logo"
                height="100%"
                width="auto"
                :alt="`${quote.sourceName} logo`"
                :webp-source="`logos/${quote.sourceId}.webp`"
                :source="`logos/${quote.sourceId}.jpg`"
                source-type="image/jpeg"/>
              <content-wrapper
                :max-width="275">
                <homepage-text
                  tag="p"
                  :styling-variant="isMobile ? 'quote' : 'quote-small'">
                  "{{ quote.quote }}"
                </homepage-text>
              </content-wrapper>
            </content-stack>
          </plain-button>
        </component>
      </content-wrapper>
      <decorative-divider variant="bottom"/>
    </content-stack>
  </homepage-section>
</template>

<script>
import ContentStack from '@/homepage/components/common/ContentStack.vue'
import ContentRow from '@/homepage/components/common/ContentRow.vue'
import HomepageImage from '@/homepage/components/common/HomepageImage.vue'
import HomepageSection from '@/homepage/components/common/HomepageSection.vue'
import HomepageText from '@/homepage/components/common/HomepageText.vue'
import ContentWrapper from '@/homepage/components/common/ContentWrapper.vue'
import PlainButton from '@/homepage/components/common/PlainButton.vue'
import SvgDecorativeQuote from './SvgDecorativeQuote.vue'
import inViewport from 'vue-in-viewport-mixin'
import DecorativeDivider from '@/homepage/components/common/DecorativeDivider.vue'
import SnapScroll from './SnapScroll.vue'

export default {
  components: {
    ContentRow,
    HomepageImage,
    HomepageSection,
    HomepageText,
    ContentStack,
    ContentWrapper,
    PlainButton,
    SvgDecorativeQuote,
    DecorativeDivider,
    SnapScroll
  },
  mixins: [inViewport],
  data () {
    return {
      newsQuotes: [
        {
          sourceName: 'Vogue',
          sourceId: 'vogue',
          sourceUrl: 'https://www.vogue.com/article/clothing-rental-subscription',
          quote: 'Those looking for help from a personal stylist will enjoy Armoire.'
        },
        {
          sourceName: 'Conde Nast Traveler',
          sourceId: 'condenast',
          sourceUrl: 'https://www.cntraveler.com/story/best-clothing-subscription-boxes',
          quote: 'As you continue to use the service, the algorithm gets smarter, showing you more and more options that suit your personal style preferences.'
        },
        {
          sourceName: 'New York Times',
          sourceId: 'nyt',
          sourceUrl: 'https://www.nytimes.com/2019/06/08/style/rent-subscription-clothing-furniture.html',
          quote: 'Owning nothing is now a luxury, thanks to a number of subscription start-ups.'
        },
        {
          sourceName: 'Refinery 29',
          sourceId: 'refinery29',
          sourceUrl: 'https://www.refinery29.com/en-us/clothing-rental-subscription-service#slide-15',
          quote: 'Make mornings a little easier by taking the guesswork out of getting dressed.'
        },
        {
          sourceName: 'BuzzFeed',
          sourceId: 'buzzfeed',
          sourceUrl: 'https://www.buzzfeed.com/allisonjiang/best-places-to-rent-clothes',
          quote: 'You get the best of both worlds with this AAPI woman–owned, sustainable brand.'
        },
        {
          sourceName: 'Pop Sugar',
          sourceId: 'popsugar',
          sourceUrl: 'https://www.popsugar.com/fashion/armoire-clothing-subscription-review-48495019',
          quote: 'I tried clothing subscription service Armoire, and I actually had fun with fashion again.'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.news-source-logo {
  height: 92px;
}

.news-source-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 0 0 28px;

  &:hover {
    filter: opacity(80%);
  }

  @include medium {
    width: 45%;
    margin-top: 40px;
    min-height: 332px;
  }

  @include large {
    width: 30%;
  }
}
</style>
