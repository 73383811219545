<template>
  <homepage-section
    :use-default-padding="false">
    <div
      :class="isMobile ? 'content-stack' : 'video-banner-wrapper'">
      <div class="video-wrapper">
        <video
          id="HIW-video"
          class="hiw-video"
          autoplay
          loop
          playsinline
          alt="Decorative video visualizing the combination of art and science. Armoire stylist headshot next to Armoire dress."
          height="100%"
          width="100%"
          muted>
          <source
            :src="previewVideoLink"
            type="video/mp4">
        </video>
        <plain-button
          type="button"
          class="play-button-wrapper"
          tag="button"
          label="Watch How It Works"
          role="button"
          alt="Play button for Watch How It Works"
          @onKeyDown="onPlayButton"
          @click="onPlayButton">
          <svg-play
            class="play-button"
            :size="isMobile ? 40 : 90"/>
          <homepage-text
            tag="p"
            styling-variant="heading3"
            class="text-white text-start"
            :class="{'mobile-btn-text' : isMobile}">
            Watch how it works
          </homepage-text>
        </plain-button>
      </div>
      <content-stack
        :spacing="isMobile ? 0 : 16"
        :alignment="isMobile ? 'center' : 'left'"
        :class="isMobile ? 'intro-content-mobile' : 'intro-content'">
        <content-stack
          :spacing="isMobile ? 10 : 16"
          :alignment="isMobile ? 'center' : 'left'">
          <homepage-text
            tag="h1"
            class="text-white"
            :class="{'text-center':isMobile}"
            styling-variant="siteheader">
            Clothing rental
            <br>for every you
          </homepage-text>
          <homepage-text
            tag="p"
            class="text-white"
            :class="{'text-center':isMobile}"
            styling-variant="copy">
            Rent from a personalized rental closet of designer brands.
          </homepage-text>
          <get-started-button
            id="get-started-header"
            location="Hero Image"
            button-variant="secondary"/>
        </content-stack>
      </content-stack>
    </div>
  </homepage-section>
</template>

<script>
import HomepageText from '@/homepage/components/common/HomepageText.vue'
import HomepageSection from '@/homepage/components/common/HomepageSection.vue'
import PlainButton from '@/homepage/components/common/PlainButton.vue'
import SvgPlay from '@/homepage/components/common/SvgPlay.vue'
import GetStartedButton from '@/homepage/components/common/GetStartedButton.vue'

import ContentStack from '@/homepage/components/common/ContentStack.vue'

export default {
  components: {
    HomepageSection,
    HomepageText,
    ContentStack,
    PlainButton,
    SvgPlay,
    GetStartedButton
  },
  data () {
    return {
      videoIsFullscreen: false
    }
  },
  computed: {
    previewVideoLink () {
      return 'https://d2wwsm0zoxn9fr.cloudfront.net/videos/homepage_video_preview_loop_desktop_v1_reduced.mp4'
    },
    fullVideoLink () {
      return 'https://d2wwsm0zoxn9fr.cloudfront.net/videos/HIW_Full_2022.mp4'
    }
  },
  watch: {
    videoIsFullscreen (state) {
      const HIWVideo = document.getElementById('HIW-video')

      if (!state) {
        HIWVideo.muted = true
        HIWVideo.classList.add('hiw-video')
        HIWVideo.src = this.previewVideoLink
      }
      if (state) {
        HIWVideo.classList.remove('hiw-video')
      }
    }
  },
  created () {
    document.addEventListener('webkitfullscreenchange', () => {
      this.videoIsFullscreen = !this.videoIsFullscreen
    })
  },
  methods: {
    onPlayButton () {
      const HIWVideo = document.getElementById('HIW-video')
      const fullVideoLink = this.fullVideoLink
      HIWVideo.src = fullVideoLink
      HIWVideo.muted = false
      HIWVideo.load()
      HIWVideo.webkitRequestFullscreen()
      HIWVideo.play()
    }
  }
}
</script>

<style lang="scss" scoped>
.intro-content {
  justify-content: center;
  text-align: left;
  padding: 0 5%;
}

.intro-content-mobile{
  padding: 40px 30px 30px;
}
.hiw-video {
  object-fit: cover;
}
.video-wrapper {
  height: 30vh;
  @include medium {
    height: 40vh;
    min-height: 425px;
  }
}
/* This used to work for the parent element of button divs */
/* But it does not work with newer browsers, the below doesn't hide the play button parent div */

video::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

video::-webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

video::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}
.mobile-btn-text {
  font-size: 10px;
}
.video-banner-wrapper {
  display: flex;
}
.play-button {
  margin-right: 15px;
}
.play-button-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: underline;
  color: white;
  top: -60px;
  left: 10px;
  margin-bottom: -70px;
  @include medium {
    top: -105px;
  }
}
</style>
