<template>
  <image-half-section
    id="sustainability-bottom"
    show-photo-on-mobile
    alt="Armoire member wearing navy top outside."
    image-url="sustainability/sustainability-bottom-l.jpg"
    mobile-image-url="sustainability/sustainability-bottom-m.jpg"
    placeholder-image-url="sustainability/sustainability-bottom-t.jpg">
    <div>
      <h2>Reduce your footprint. Maximize your wardrobe.</h2>
      <p>The future of fashion is here.</p>
    </div>
    <primary-button
      class="renting-button">
      Get Started
    </primary-button>
  </image-half-section>
</template>

<script>
import ImageHalfSection from '@/homepage/components/common/ImageHalfSection'
import PrimaryButton from '@/homepage/components/common/PrimaryButton.vue'
export default {
  name: 'BottomBanner',
  components: {
    ImageHalfSection,
    PrimaryButton
  }
}
</script>

<style lang="scss" scoped>
  .renting-button {
    margin-top: 16px;
  }

  #sustainability-bottom {
    background-color: $champagne;
  }
</style>
