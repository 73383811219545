<template>
  <div class="plan-card border p-sm">
    <p
      v-if="plan.mostPopular"
      class="best-value small-text fw-bold">
      Best Value
    </p>
    <div class="plan-card-inner">
      <h2
        class="line-decorator"
        v-html="planOnNextLine(plan.name)"/>
      <p class="fw-semibold color-plum my-sm">
        <span
          v-if="plan.promoPrice !== plan.basePrice || referralCampaign">
          <b>{{ planPromoPrice(plan, referralCampaign) }} first month</b> ({{ plan.basePrice }}/month after)
        </span>
        <span v-else>
          <b>{{ plan.basePrice }} per month</b>
        </span>
      </p>
      <span
        class="mb-md">
        <p
          v-for="feature in plan.features"
          :key="feature"
          v-html="feature"/>
      </span>
      <TrackingButton
        :text="`Try ${plan.name}`"
        app-entry-point="homepage"
        fixed-width
        :to="plan.signupUrl"
        :event-label="`Plan Card - ${plan.name}`"/>
    </div>
  </div>
</template>
<script setup>
import TrackingButton from '@shared/components/ADORN/TrackingButton.vue'
import { usePlans } from '@shared/composables/plans.js'

defineProps({
  plan: {
    type: Object,
    required: true
  },
  referralCampaign: {
    type: Object,
    default: () => {}
  }
})
const { planPromoPrice, planOnNextLine } = usePlans()
</script>
<style scoped lang="scss">
.plan-card {
  aspect-ratio: 8/7;
  height: fit-content;
  max-width: fit-content;
  min-width: 350px;
  background-color: $white;
}
.best-value {
  background-color: $champagne;
  color: $plum;
  padding: $spacing-xxs $spacing-xxl;
  width: fit-content ;
  position: relative;
  top: 18px;
  margin: -30px auto 0 auto;
}
.plan-card-inner {
  border: $spacing-xs solid $champagne;
  display: flex;
  flex-direction: column;
  padding: 0 $spacing-sm;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.line-decorator {
  display: flex;
  width: 100%;
  white-space: nowrap;
  gap: $spacing-md;
  &::before {
    content: '';
    display: block;
    background-color: $ash;
    width: 100%;
    height: 1px;
    position: relative;
    top: 30px;
  }
  &::after{
    content: '';
    display: block;
    background-color: $ash;
    width: 100%;
    height: 1px;
    position: relative;
    top: 30px;
  }
}
</style>
