<template>
  <div>
    <div
      ref="scrollWrapper"
      class="scroll-wrapper"
      :style="{ width: `${width}px` }">
      <slot/>
    </div>
    <content-row :spacing="8">
      <div
        v-for="(_, index) in length"
        :key="index"
        class="dot"
        :class="[dotVariant, { 'selected': index === currentIndex }]"
        @click="clickDot(index)"/>
    </content-row>
  </div>
</template>

<script>
import ContentRow from '@/homepage/components/common/ContentRow.vue'

export default {
  components: {
    ContentRow
  },
  props: {
    dotVariant: {
      type: String,
      default: 'gray',
      validator: v => v.match(/(gray|white)/)
    },
    initialIndex: {
      type: Number,
      default: 0
    },
    length: {
      type: Number,
      default: 1
    },
    alwaysScroll: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      width: window.innerWidth - 100,
      currentIndex: this.initialIndex
    }
  },
  created () {
    window.addEventListener('resize', () => {
      this.width = window.innerWidth - 100
    })
  },
  activated () {
    this.currentIndex = this.initialIndex
    this.$emit('update-index', this.currentIndex)
    this.initializeScroll()
  },
  mounted () {
    this.initializeScroll()
    if (this.$refs.scrollWrapper) {
      this.$refs.scrollWrapper.onscroll = ({ target: { scrollLeft } }) => {
        this.currentIndex = Math.round(scrollLeft / this.width)
        this.$emit('update-index', this.currentIndex)
      }
    }
  },
  deactivated () {
    this.currentIndex = 0
  },
  methods: {
    clickDot (index) {
      this.$refs.scrollWrapper.scrollLeft = index * this.width
      this.currentIndex = index
      this.$emit('update-index', index)
    },
    // Hack for setting initial image in the scroll wrapper
    initializeScroll () {
      setTimeout(() => {
        if (this.$refs.scrollWrapper) {
          this.$refs.scrollWrapper.scrollLeft = this.currentIndex * this.width
        }
      }, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
  .dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    cursor: pointer;
    background-color: $ash;
    @include transition-base-all;

    &.white {
      border: 1px solid white;
      background: rgba($white, 0);
    }

    &.selected {
      background-color: $pewter;

      &.white {
        background-color: $white;
      }
    }
  }

  .scroll-wrapper {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
  }

  .scroll-wrapper > :deep(*) {
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }

  ::-webkit-scrollbar {
    display: none;
  }
</style>
