export const DisplaySizeMixin = {
  data () {
    return {
      windowWidth: window.innerWidth
    }
  },
  computed: {
    isMobile () {
      return this.windowWidth < 768
    }
  },
  created () {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  }
}
