
// Flattens the keys of an object
// { a: { b: { c: 'd' } } } => { 'a.b.c': 'd' }
export function flattenKeys (obj, prefix = null) {
  const prefixKey = prefix ? `${prefix}.` : ''
  return Object.keys(obj).reduce((acc, key) => {
    const newKey = `${prefixKey}${key}`
    const value = obj[key]
    if (typeof value === 'object') {
      return { ...acc, ...flattenKeys(value, newKey) }
    }
    return { ...acc, [newKey]: value }
  }, {})
}
