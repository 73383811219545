<template>
  <default-button
    :type="type"
    :tag="tag"
    :to="to"
    :href="href"
    class="primary-button"
    :class="[
      {'inverse': variant === 'inverse'},
      {'block': block}
    ]"
    @click="$emit('click')">
    <slot/>
  </default-button>
</template>

<script>
import DefaultButton from './DefaultButton'

export default {
  name: 'PrimaryButton',
  components: {
    DefaultButton
  },
  props: {
    type: {
      type: String,
      default: 'a'
    },
    tag: {
      type: String,
      default: 'a',
      validator: value => {
        return value.match(/(button|a)/)
      }
    },
    to: {
      type: Object,
      default: null
    },
    href: {
      type: String,
      default: null
    },
    variant: {
      type: String,
      default: 'primary',
      validator: value => {
        return value.match(/(primary|inverse)/)
      }
    },
    block: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.primary-button {
  background-color: $primary-button-background-color;
  font-family: $buttons-font-family;
  color: $primary-button-text;
  &.block {
    width: 100%;
  }
  &.inverse {
    background-color: $white;
    color: $plum;
  }

  &:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba($pewter, 0.7);
    box-shadow: 0 0 0 0.2rem rgba($pewter, 0.7);
  }

  &:hover {
    &.inverse {
      background-color: $white;
      color: rgba($plum, 0.7)
    }
    background-color: $primary-button-background-hover;
    color: $primary-button-text-hover;
  }
}
</style>
