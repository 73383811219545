import { computed, ref } from 'vue'
import { parseInline } from 'marked'
import rawData from '@/homepage/data/faq.json'
import { flattenKeys } from '@shared/utils/obj.js'

export default function useFaq () {
  const plans = ref(JSON.parse(JSON.stringify(window.armoire.plans)))
  const emails = ref({
    hi: 'hi@armoire.style'
  })
  const links = ref({
    gifts: 'https://support.armoire.style/gifts'
  })

  const replacements = computed(() => ({
    ...flattenKeys(plans.value, 'plan'),
    ...flattenKeys(emails.value, 'email'),
    ...flattenKeys(links.value, 'link')
  }))

  const faq = computed(() => {
    return Object.keys(rawData).reduce((acc, key) => {
      const group = rawData[key]
      return {
        ...acc,
        [key]: {
          title: replacePlaceholders(group.title),
          data: group.questions.map(q => ({
            question: replacePlaceholders(q.question),
            answer: parse(q.answer)
          }))
        }
      }
    }, {})
  })

  function replacePlaceholders (template) {
    const placeholderRegex = /{\s*([a-zA-z0-9-.]+)\s*}/g
    return template.replace(placeholderRegex, (match, key) => replacements.value[key] ?? match)
  }

  function parse (template) {
    return parseInline(replacePlaceholders(template))
  }

  return {
    plans,
    emails,
    links,
    replacements,
    faq,
    replacePlaceholders,
    parse
  }
}
