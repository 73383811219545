
export function getRefereeBonusItems (referralCampaign, plan = null) {
  if (!referralCampaign) { return 0 }
  let bestReferralPlan = null
  if (plan) {
    const matches = referralCampaign.planDiscounts.filter(x => x.plan.id === plan.id)
    if (matches.length > 0) {
      bestReferralPlan = matches[0]
    }
  } else {
    bestReferralPlan = getBestReferralPlan(referralCampaign)
  }
  if (bestReferralPlan) {
    return bestReferralPlan.refereeBonusItems
  }
  return referralCampaign ? referralCampaign.refereeBonusItems : 0
}

export function getRefereeDiscount (referralCampaign) {
  const bestReferralPlan = getBestReferralPlan(referralCampaign)
  if (bestReferralPlan) {
    return bestReferralPlan.effectivePercentOff
  }
  return referralCampaign ? referralCampaign.refereePlanPercentOff : ''
}

export function getBestReferralPlan (referralCampaign) {
  if (referralCampaign && referralCampaign.planDiscounts.length > 0) {
    const plans = referralCampaign.planDiscounts.filter(x => x.isFeaturedDeal)
    if (plans.length > 0) { return plans[0] }
    if (referralCampaign.planDiscounts.length > 0) { return referralCampaign.planDiscounts[0] }
  }
  return null
}

export function getReferralSavedAmount (referralCampaign, plan) {
  if (referralCampaign) {
    const matches = referralCampaign.planDiscounts.filter(x => x.plan.id === plan.id)
    if (matches.length > 0) { return matches[0].effectiveAmountOff }
    // fallback
    return plan.basePriceCents * referralCampaign.refereePlanPercentOff / 100
  }
  return null
}

export function getReferralDiscountPrice (referralCampaign, plan) {
  if (referralCampaign) {
    const matches = referralCampaign.planDiscounts.filter(x => x.plan.id === plan.id)
    if (matches.length > 0) { return matches[0].effectivePrice }
    // fallback
    return plan.basePriceCents - (plan.basePriceCents * referralCampaign.refereePlanPercentOff / 100)
  }
  return plan.basePriceCents
}

export function headerText (clientName, referralCampaign) {
  let bonusItemsText = ''
  const bonusItems = getRefereeBonusItems(referralCampaign)
  if (bonusItems) {
    bonusItemsText = `, plus ${bonusItems} free bonus item`
    if (bonusItems > 1) { bonusItemsText += 's' }
  }
  return `${clientName} gifted you our best deal: Up to ${getRefereeDiscount(referralCampaign)}% off your first month of Armoire${bonusItemsText}!`
}

export const subheaderText = 'You won\'t find a better deal anywhere else.'

export const bodyText = 'Rent your everyday, personalized wardrobe with Armoire for commitment-free, sustainable fashion.'
