<template>
  <homepage-section
    variant="white">
    <content-stack :spacing="!isMobile ? 32 : 2">
      <decorative-divider/>
      <content-wrapper>
        <content-stack
          :spacing="32">
          <component
            :is="isMobile ? 'content-stack' : 'content-row'"
            :spacing="24">
            <div class="video-container">
              <video
                v-if="inViewport.now"
                loop
                muted
                width="100%"
                height="auto"
                autoplay="true">
                <source
                  :src="isMobile ?
                    getImageUrl('loopingvideos/nicest_place_mobile.mp4') :
                    getImageUrl('loopingvideos/nicest_place.mp4')"
                  type="video/mp4">
              </video>
            </div>
            <content-stack
              alignment="left">
              <content-wrapper :max-width="440">
                <content-stack
                  :spacing="8"
                  alignment="left">
                  <homepage-text
                    tag="h2"
                    class="text-start"
                    styling-variant="heading1">
                    “The nicest place on the internet”
                  </homepage-text>
                  <homepage-text
                    tag="p"
                    class="text-start"
                    styling-variant="copy-small">
                    When you access our members-only PWR Feed, you'll be part of an inspiring and supportive community of ladies who share a passion for looking and feeling their best. Don’t know what to wear to that big event coming up? Need a power outfit to knock that next interview out of the park? Browse the PWR Feed to get inspired by our community!
                  </homepage-text>
                </content-stack>
              </content-wrapper>
              <get-started-button location="Nicest Place"/>
            </content-stack>
          </component>
          <component
            :is="isMobile ? 'content-stack' : 'content-row'"
            :spacing="isMobile ? 16 : 24"
            class="border-top find-fit">
            <content-stack
              alignment="left">
              <content-wrapper :max-width="440">
                <content-stack
                  :spacing="8"
                  alignment="left">
                  <content-wrapper
                    :style="{ marginLeft: 0 }">
                    <homepage-text
                      tag="h2"
                      class="text-start"
                      styling-variant="heading1">
                      Rent designer clothes that fit
                    </homepage-text>
                  </content-wrapper>
                  <homepage-text
                    tag="p"
                    class="text-start"
                    styling-variant="copy-small">
                    Armoire is dedicated to celebrating YOU and finding styles that make you feel great in your own skin. We carry sizes 0-3X, and are always adding new styles for every body type. Have questions about finding the right fit? Our stylists are available for personal, one-on-one fit appointments to help you find the best styles for your body shape.
                  </homepage-text>
                </content-stack>
              </content-wrapper>
              <get-started-button location="Rent Designer"/>
            </content-stack>
            <homepage-image
              v-if="inViewport.now"
              class="section-image"
              webp-source="findfit@2x.webp"
              source="findfit.jpg"
              source-type="image/jpeg"
              width="100%"
              height="auto"
              alt="A collage of 6 photos of Armoire member Rachel wearing Armoire jumpsuits, dresses, sweaters, and pants"/>
          </component>
        </content-stack>
      </content-wrapper>
      <decorative-divider
        variant="bottom"/>
    </content-stack>
  </homepage-section>
</template>

<script>
import ContentRow from '@/homepage/components/common/ContentRow.vue'
import ContentStack from '@/homepage/components/common/ContentStack.vue'
import ContentWrapper from '@/homepage/components/common/ContentWrapper.vue'
import DecorativeDivider from '@/homepage/components/common/DecorativeDivider.vue'
import HomepageImage from '@/homepage/components/common/HomepageImage.vue'
import HomepageSection from '@/homepage/components/common/HomepageSection.vue'
import HomepageText from '@/homepage/components/common/HomepageText.vue'
import MarketingButton from '@/homepage/components/common/MarketingButton.vue'
import inViewport from 'vue-in-viewport-mixin'
import GetStartedButton from '@/homepage/components/common/GetStartedButton.vue'
import { getImageUrl } from '@/homepage/utils/url.js'

export default {
  components: {
    ContentRow,
    DecorativeDivider,
    HomepageSection,
    HomepageText,
    HomepageImage,
    ContentStack,
    MarketingButton,
    ContentWrapper,
    GetStartedButton
  },
  mixins: [inViewport],
  methods: {
    getImageUrl
  }
}
</script>

<style lang="scss" scoped>
.section-image, .video-container {
  width: 100%;
  max-width: 400px;
  flex-shrink: 0;

  @include medium {
    width: 48%;
    max-width: unset;
  }
}

.find-fit {
  padding-top: 32px;
  flex-direction: column-reverse;

  @include medium {
    flex-direction: row;
  }
}
</style>
