<template>
  <homepage-section id="brands">
    <content-wrapper>
      <content-stack>
        <content-wrapper :max-width="686">
          <content-stack>
            <content-wrapper :max-width="420">
              <homepage-text
                tag="h2"
                class="text-center text-white"
                styling-variant="heading1">
                Find your (new) favorite brands
              </homepage-text>
            </content-wrapper>
            <homepage-text
              tag="p"
              class="text-center text-white"
              styling-variant="copy-small">
              We scour the fashion universe to find the best styles for your personalized closet, including top designers you know and trust and new brands you'll love discovering.
            </homepage-text>
          </content-stack>
        </content-wrapper>
        <brand-grid v-if="inViewport.now"/>
        <homepage-get-started-lockup
          text-class="text-white"
          location="Brands"
          button-variant="secondary">
          Clothing rental from all of these brands and hundreds more.<br>
          Let us know your favorite brands in our Style Quiz.
        </homepage-get-started-lockup>
        <!-- Hold until we have a brand index -->
        <!-- <text-link
          aria-label="Link to "
          aria-role="link"
          variant="secondary"
          type="a"
          href="">
          Explore all of our brands
        </text-link> -->
      </content-stack>
    </content-wrapper>
  </homepage-section>
</template>

<script>
import BrandGrid from './BrandGrid.vue'
import ContentStack from '@/homepage/components/common/ContentStack.vue'
import ContentWrapper from '@/homepage/components/common/ContentWrapper.vue'
import HomepageSection from '@/homepage/components/common/HomepageSection.vue'
import HomepageText from '@/homepage/components/common/HomepageText.vue'
import HomepageGetStartedLockup from '@/homepage/components/common/HomepageGetStartedLockup.vue'
import inViewport from 'vue-in-viewport-mixin'
// import TextLink from './TextLink.vue'

export default {
  components: {
    ContentWrapper,
    HomepageSection,
    HomepageText,
    HomepageGetStartedLockup,
    ContentStack,
    BrandGrid
    // TextLink
  },
  mixins: [inViewport]
}
</script>
