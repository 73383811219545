<template>
  <homepage-section
    :variant="backgroundColor"
    :class="[{ 'row' : showVariant }, { 'content' : showVariant }]">
    <content-wrapper>
      <content-stack :spacing="showVariant ? 0 : 24">
        <HomepageRedesignExperiment>
          <template #default>
            <homepage-text
              class="text-white"
              tag="h2"
              styling-variant="heading1">
              Membership Perks
            </homepage-text>
          </template>
          <template #variantRedesign>
            <SectionHeadline
              title="Membership Perks"/>
          </template>
        </HomepageRedesignExperiment>
        <component
          :is="isMobile ? 'content-stack' : 'content-row'"
          :spacing="isMobile ? 4 : 12"
          class="flex-wrap">
          <component
            :is="isMobile ? 'content-row' : 'content-stack'"
            v-for="(perk, idx) in perks"
            :key="`perk-${idx}`"
            class="perk"
            :class="{ 'border-top': !showVariant }"
            :spacing="12">
            <homepage-image
              v-if="inViewport.now && !showVariant"
              width="100%"
              height="auto"
              class="perk-image"
              :alt="`Decorative illustration of ${perk.title} ${perk.title2}`"
              :webp-source="`howitworks/jan2022/membershipperks/${idx + 1}.webp`"
              :source="`howitworks/jan2022/membershipperks/${idx + 1}.png`"
              source-type="image.png"/>
            <homepage-image
              v-if="inViewport.now && showVariant"
              width="100%"
              height="auto"
              class="perk-image"
              :alt="`Decorative illustration of ${perk.title} ${perk.title2}`"
              :source="`how-it-works/membership-perks/${idx + 1}.svg`"
              source-type="image.svg"/>
            <content-stack
              :spacing="4"
              :alignment="isMobile ? 'left' : 'center'">
              <HomepageRedesignExperiment>
                <template #default>
                  <homepage-text
                    class="headline text-white"
                    :class="isMobile ? 'text-start' : 'text-center'"
                    styling-variant="heading3">
                    {{ perk.title }}<span v-if="perk.title2"><br v-if="!isMobile"> {{ perk.title2 }}</span>
                  </homepage-text>
                </template>
                <template #variantRedesign>
                  <h3
                    class="text-armor"
                    :class="isMobile ? 'text-start' : 'text-center'">
                    {{ perk.title }}<span v-if="perk.title2"><br v-if="!isMobile"> {{ perk.title2 }}</span>
                  </h3>
                </template>
              </HomepageRedesignExperiment>
              <HomepageRedesignExperiment>
                <template #default>
                  <homepage-text
                    class="text-white"
                    :class="isMobile ? 'text-start' : 'text-center'"
                    styling-variant="copy-small">
                    {{ perk.subtitle }}
                  </homepage-text>
                </template>
                <template #variantRedesign>
                  <p
                    class="text-armor"
                    :class="isMobile ? 'text-start' : 'text-center'">
                    {{ perk.subtitle }}
                  </p>
                </template>
              </HomepageRedesignExperiment>
            </content-stack>
          </component>
        </component>
      </content-stack>
    </content-wrapper>
  </homepage-section>
</template>

<script>
import ContentRow from '@/homepage/components/common/ContentRow.vue'
import ContentStack from '@/homepage/components/common/ContentStack.vue'
import ContentWrapper from '@/homepage/components/common/ContentWrapper.vue'
import HomepageImage from '@/homepage/components/common/HomepageImage.vue'
import HomepageSection from '@/homepage/components/common/HomepageSection.vue'
import HomepageText from '@/homepage/components/common/HomepageText.vue'
import SectionHeadline from '@/components/global/SectionHeadline.vue'
import inViewport from 'vue-in-viewport-mixin'
import HomepageRedesignExperiment from '@shared/components/HomepageRedesignExperiment.vue'
import { useHomepageRedesignExperiment } from '@shared/composables/experiment.js'

export default {
  components: {
    ContentStack,
    ContentWrapper,
    HomepageImage,
    HomepageSection,
    HomepageText,
    ContentRow,
    SectionHeadline,
    HomepageRedesignExperiment
  },
  mixins: [inViewport],
  props: {
    location: {
      type: String,
      default: 'how-it-works'
    }
  },
  setup () {
    const { showVariant } = useHomepageRedesignExperiment()
    return {
      showVariant
    }
  },
  data () {
    return {
      perks: [
        {
          title: 'Thousands of',
          title2: 'high-end styles',
          subtitle: '75K+ high-end items from hundreds of top designers'
        },
        {
          title: 'Buy what you love',
          title2: '(at a discount)',
          subtitle: 'Purchase your favorite items at members-only prices'
        },
        {
          title: 'Full-service',
          title2: 'styling',
          subtitle: 'Our stylists can curate a closet or case just for you'
        },
        {
          title: 'Free & fast shipping,',
          title2: 'both ways',
          subtitle: 'Reusable bag & prepaid shipping label are included for easy returns'
        },
        {
          title: 'Cleaning is',
          title2: 'on us',
          subtitle: 'Don\'t worry about laundry or dry cleaning, it\'s included!'
        },
        {
          title: 'Never be without',
          title2: 'clothes',
          subtitle: 'Keep your current case at home until your new items arrive'
        }
      ]
    }
  },
  computed: {
    backgroundColor () {
      if (this.location === 'refer') {
        return 'white'
      } else {
        if (this.showVariant) {
          return 'origami'
        } else {
          return 'plum'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 0px;
  margin-bottom: 30px;
}

.perk-image {
  max-width: 60px;
  max-height: 60px;
  flex-shrink: 0;
  flex-grow: 0;

  @include medium {
    max-width: 80px;
    max-height: 80px;
  }
}

.perk {
  width: 100%;
  max-width: 480px;
  padding: 20px 0 16px;
  flex-grow: 0;
  justify-content: flex-start;

  @include medium {
    width: 30%;
    max-width: unset;
    justify-content: flex-start;
    flex-grow: 1;
    min-height: 272px;
  }
}

.border-top {
  border-top: $default-border;
}

.origami {
  background-color: $origami;
}
</style>
