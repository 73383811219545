<template>
  <div class="infocard">
    <div
      class="infocard-image"
      :class="imageSize">
      <img
        v-if="imageUrl"
        class="infocard-inner-image"
        :src="imageUrl">
    </div>
    <div
      v-if="hasOverlaySlot"
      class="infocard-overlay"
      :class="`${overlayPosition} ${overlaySize}`">
      <slot name="overlay"/>
    </div>
    <div
      class="infocard-content"
      :class="{ 'with-overlay': hasOverlaySlot }">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfoCardBase',
  props: {
    imageUrl: {
      type: String,
      default: null
    },
    imageSize: {
      type: String,
      default: 'medium',
      validator: value => {
        return value.match(/(short|medium|tall)/)
      }
    },
    overlayPosition: {
      type: String,
      default: 'center',
      validator: value => {
        return value.match(/(left|center)/)
      }
    },
    overlaySize: {
      type: String,
      default: 'small',
      validator: value => {
        return value.match(/(small|large)/)
      }
    }
  },
  computed: {
    hasOverlaySlot () {
      return this.$slots.overlay
    }
  }
}
</script>

<style lang="scss" scoped>
.infocard {
  max-width: 300px;
  box-shadow: 0px 0px 8px rgba($pewter, 0.2);
}

.infocard-image {
  width: 100%;

  &.short {
    height: 140px;
  }

  &.medium {
    height: 170px;
  }

  &.tall {
    height: 252px;
  }

  .infocard-inner-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.infocard-overlay {
  position: relative;
  border-radius: 50%;
  background-color: $velvet;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  margin: -1.5rem auto 0;

  &.large {
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    margin: -2rem auto 0;
  }

  /** some defaults for any plain text that is passed */
  color: $secondary;
  font-family: $headings-font-family;
  font-weight: $font-weight-semibold;
  font-size: 1.75rem;
  text-align: center;

  &.left {
    margin-left: 24px;
  }
}

.infocard-content {
  padding: 1.25rem;
  background-color: $secondary;
  text-align: center;
  font-family: $body-font-family;
  line-height: $body-line-height;

  &.with-overlay {
    margin-top: -4rem;
    padding-top: 4.5rem;
  }
}
</style>
