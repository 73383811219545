<template>
  <div class="email-capture-banner">
    <h4 class="header">
      Let’s keep in touch
    </h4>
    <p>Sustainable fashion, sent straight to your inbox.</p>
    <email-capture
      button-text="Submit"
      success-header="Email submitted!"
      success-body="See you in your inbox"
      source="landing-sustainability"/>
  </div>
</template>

<script>
import EmailCapture from '@/homepage/components/common/EmailCapture.vue'

export default {
  name: 'EmailCaptureBanner',
  components: {
    EmailCapture
  }
}

</script>
<style lang="scss" scoped>
  .email-capture-banner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: $champagne;
    padding: 48px;
    text-align: center;
    align-items: center;
  }

  .header {
    margin: 0;
  }
</style>
