<template>
  <div class="brands">
    <h2 class="section-header">
      {{ title }}
    </h2>
    <p class="sub-header">
      {{ subtitle }}
    </p>
    <div
      class="brand-logo-bar"
      :class="{ 'see-more-open': (seeMoreOpen || showAllOnMobile) }">
      <div
        v-for="brandImage in brandImages"
        :key="brandImage.alt"
        class="brand-logo">
        <img
          v-if="inViewport.now"
          :src="getImageUrl(brandImage.url)"
          :alt="brandImage.alt">
      </div>
    </div>
    <button
      v-if="!showAllOnMobile"
      class="see-more-button"
      @click="seeMoreOpen = !seeMoreOpen">
      See {{ ` ${seeMoreOpen ? "Less" : "More"}` }}
      <svg-chevron-down
        class="see-more-chevron"
        :class="{ 'up': seeMoreOpen }"/>
    </button>
  </div>
</template>

<script>
import SvgChevronDown from '@/homepage/components/common/SvgChevronDown.vue'
import { getImageUrl } from '@/homepage/utils/url.js'
import inViewport from 'vue-in-viewport-mixin'

export default {
  name: 'BrandsList',
  components: {
    SvgChevronDown
  },
  mixins: [inViewport],
  props: {
    title: {
      type: String,
      default: 'Access hundreds of high-end brands'
    },
    subtitle: {
      type: String,
      default: 'Our inventory is hand-selected to include top designers you recognize and inspiring independent labels to refresh your closet.'
    },
    showAllOnMobile: {
      type: Boolean,
      default: false
    },
    brandImages: {
      type: Array,
      default: () => [
        {
          alt: 'Adrianna Papell',
          url: 'how-it-works/brand-logos/adrianna-papell.png'
        },
        {
          alt: 'Boden',
          url: 'how-it-works/brand-logos/boden.png'
        },
        {
          alt: 'Brass',
          url: 'how-it-works/brand-logos/brass.png'
        },
        {
          alt: 'Cupcakes and Cashmere',
          url: 'how-it-works/brand-logos/cupcakes-and-cashmere.png'
        },
        {
          alt: 'Diane Von Furstenberg',
          url: 'how-it-works/brand-logos/diane-von-furstenberg.png'
        },
        {
          alt: 'Equipment',
          url: 'how-it-works/brand-logos/equipment.png'
        },
        {
          alt: 'French Connection',
          url: 'how-it-works/brand-logos/french-connection.png'
        },
        {
          alt: 'Joie',
          url: 'how-it-works/brand-logos/joie.png'
        },
        {
          alt: 'Lacausa',
          url: 'how-it-works/brand-logos/lacausa.png'
        },
        {
          alt: 'Nicole Miller',
          url: 'how-it-works/brand-logos/nicole-miller.png'
        },
        {
          alt: 'Of-Mercer',
          url: 'how-it-works/brand-logos/of-mercer.png'
        },
        {
          alt: 'Paige',
          url: 'how-it-works/brand-logos/paige.png'
        },
        {
          alt: 'Rails',
          url: 'how-it-works/brand-logos/rails.png'
        },
        {
          alt: 'Scotch and Soda',
          url: 'how-it-works/brand-logos/scotch-and-soda.png'
        },
        {
          alt: 'Tahari',
          url: 'how-it-works/brand-logos/tahari.png'
        },
        {
          alt: 'Tart',
          url: 'how-it-works/brand-logos/tart.png'
        },
        {
          alt: 'White and Warren',
          url: 'how-it-works/brand-logos/white-and-warren.png'
        },
        {
          alt: 'Yumi Kim',
          url: 'how-it-works/brand-logos/yumi-kim.png'
        },
        {
          alt: 'Zuri',
          url: 'how-it-works/brand-logos/zuri.png'
        }
      ]
    }
  },
  data () {
    return {
      seeMoreOpen: false
    }
  },
  methods: {
    getImageUrl
  }
}
</script>

<style lang="scss" scoped>
.brands {
    background: $origami;
    padding: 36px 4px;

    @include medium {
        padding: 64px 0;
    }

    .section-header {
        margin-top: 0;
        padding: 0 16px;
    }

    .sub-header {
        margin: 16px;
    }
}

.see-more-button {
    display: flex;
    padding: 12px;
    margin: 0 auto;
    cursor: pointer;
    font-family: $body-font-family;
    font-size: $body-font-size;
    color: $primary-button-background-color;
    font-weight: $font-weight-bold;
    text-decoration: underline;
    border: none;
    background-color: inherit;

    &:hover {
        color: $plum;
    }

    &:hover, &:active, &:focus {
        outline: none;
    }

    @include small {
        display: none;
    }

    .see-more-chevron {
        height: 12px;
        width: 12px;
        margin: 6px 8px;

        @include transition-base-all;
        &.up {
            transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
        }
    }
}

.brand-logo-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
    max-height: 300px;

    @include transition-base-all;
    &.see-more-open {
        max-height: 1000px;
    }

    @include small {
        padding: 20px 20px 40px;
        max-width: 1300px;
        margin: 0 auto;
        max-height: fit-content;
    }

   .brand-logo {
        height: 72px;
        line-height: 40px;
        padding: 16px;

        @include small {
            height: 108px;
            padding: 36px 24px;
        }

        img {
            max-height: 40px;
            max-width: 40vw;
            vertical-align: middle;

            @include small {
              max-height: 46px;
            }
        }

   }
}

</style>
