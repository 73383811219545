<template>
  <svg
    v-if="variant === 'top'"
    width="160"
    :height="svgHeight"
    viewBox="0 0 160 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <title>Decorative divider</title>
    <path
      d="M0 11.5L160 11.5"
      class="decorative-divider"/>
    <path
      d="M50 0.5L110 0.49999"
      class="decorative-divider"/>
  </svg>
  <svg
    v-else-if="variant === 'bottom'"
    width="160"
    :height="svgHeight"
    viewBox="0 0 160 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <title>Decorative divider</title>
    <path
      d="M160 0.5L-7.18236e-06 0.500014"
      class="decorative-divider"/>
    <path
      d="M110 11.5L50 11.5"
      class="decorative-divider"/>
  </svg>
  <svg
    v-else-if="variant === 'vertical-dashed'"
    :height="svgHeight"
    viewBox="0 0 2 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <title>Decorative divider</title>
    <line
      x1="1"
      y1="-2.18557e-08"
      x2="1"
      y2="60"
      class="decorative-divider"
      stroke-dasharray="4 4"/>
  </svg>
  <svg
    v-else-if="variant === 'vertical'"
    :height="svgHeight"
    viewBox="0 0 2 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <title>Decorative divider</title>
    <path
      d="M1 32L1 3.42727e-07"
      class="decorative-divider"/>
  </svg>
  <svg
    v-else-if="variant === 'horizontal'"
    :height="svgHeight"
    viewBox="0 0 49 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <title>Decorative divider</title>
    <line
      y1="1.5"
      x2="49"
      y2="1.5"
      class="decorative-divider"
      stroke-width="2"/>
  </svg>
  <svg
    v-else-if="variant === 'square'"
    :width="height"
    :height="height"
    viewBox="0 0 209 209"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <title>Decorative divider</title>
    <rect
      x="3.4231"
      y="3.42285"
      width="202"
      height="202"
      class="decorative-divider"
      :class="color"
      stroke-width="6"/>
  </svg>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'top',
      validator: v => v.match(/(top|bottom|horizontal|vertical|vertical-dashed|square)/)
    },
    color: {
      type: String,
      default: 'velvet',
      validator: v => v.match(/(velvet|champagne)/)
    },
    height: {
      type: Number,
      default: null
    }
  },
  computed: {
    svgHeight () {
      if (this.height) return this.height

      switch (this.variant) {
        case 'top':
        case 'bottom':
          return 12
        case 'vertical-dashed':
          return 60
        case 'square':
          return 209
        case 'vertical':
        default:
          return 32
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.decorative-divider {
  stroke: $velvet;

  &.champagne {
    stroke: $champagne;
  }
}

</style>
