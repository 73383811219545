<template>
  <div>
    <top-banner class="sustainability-banner"/>
    <sustainability-details-icons/>
    <email-capture-banner/>
    <sustainability-details-cards/>
    <sustainable-brands/>
    <bottom-banner/>
  </div>
</template>

<script>
import EmailCaptureBanner from './EmailCaptureBanner'
import TopBanner from './TopBanner.vue'
import BottomBanner from './BottomBanner'
import SustainableBrands from './SustainableBrands'
import SustainabilityDetailsCards from './SustainabilityDetailsCards'
import SustainabilityDetailsIcons from './SustainabilityDetailsIcons'

export default {
  name: 'SustainabilityHome',
  components: {
    TopBanner,
    BottomBanner,
    EmailCaptureBanner,
    SustainableBrands,
    SustainabilityDetailsCards,
    SustainabilityDetailsIcons
  }
}

</script>
<style lang="scss" scoped>
.sustainability-banner {
  margin-top: 80px;
}

</style>
