
export function getImageUrl (filename, retina = false) {
  const extension = filename.substring(filename.lastIndexOf('.') + 1)
  let url = 'https://d2wwsm0zoxn9fr.cloudfront.net/images/' + filename
  if (retina) {
    url = url.substring(0, url.lastIndexOf('.')) + '@2x.' + extension
  }
  return url
}

export function buildUrl (path) {
  const url = new URL(path, window.location.origin)
  if (url.hostname === 'localhost') {
    // Dev server backend runs on a different port.
    url.port = '8000'
  }
  return url.toString()
}
