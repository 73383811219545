<template>
  <div class="row pb-xl">
    <HomepageSection
      title="In The News"/>
    <TickerLoopScroll
      :items="newsItems"
      :col-sizes="{ mobile: 12, tablet: 6, desktop: 3 }"
      :duration="65">
      <template #default="{ item }">
        <a
          :href="item.sourceUrl"
          target="_blank"
          @click="trackClickEvent('Homepage', `Link to article ${item.sourceUrl}`)">
          <div class="news-card d-flex text-center align-items-center flex-column">
            <div class="press-logo-wrapper">
              <img
                class="press-logo"
                :class="item.sourceId"
                :src="getImageUrl(`logos/svg/${item.sourceId}.svg`)">
            </div>
            <p class="quote px-xl">
              "{{ item.quote }}"
            </p>
          </div>
        </a>
      </template>
    </TickerLoopScroll>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import TickerLoopScroll from '@shared/components/ADORN/TickerLoopScroll.vue'
import { getImageUrl } from '@/homepage/utils/url.js'
import HomepageSection from './HomepageSection.vue'
import useAnalytics from '@shared/composables/analytics.js'

const { trackClickEvent } = useAnalytics()

const newsItems = computed(() => [
  {
    sourceName: 'Vogue',
    sourceId: 'vogue',
    sourceUrl: 'https://www.vogue.com/article/clothing-rental-subscription',
    quote: 'Those looking for help from a personal stylist will enjoy Armoire.'
  },
  {
    sourceName: 'Conde Nast Traveler',
    sourceId: 'condenast',
    sourceUrl: 'https://www.cntraveler.com/story/best-clothing-subscription-boxes',
    quote: 'As you continue to use the service, the algorithm gets smarter, showing you more and more options that suit your personal style preferences.'
  },
  {
    sourceName: 'New York Times',
    sourceId: 'nyt',
    sourceUrl: 'https://www.nytimes.com/2019/06/08/style/rent-subscription-clothing-furniture.html',
    quote: 'Owning nothing is now a luxury, thanks to a number of subscription start-ups.'
  },
  {
    sourceName: 'Refinery 29',
    sourceId: 'refinery29',
    sourceUrl: 'https://www.refinery29.com/en-us/clothing-rental-subscription-service#slide-15',
    quote: 'Make mornings a little easier by taking the guesswork out of getting dressed.'
  },
  {
    sourceName: 'BuzzFeed',
    sourceId: 'buzzfeed',
    sourceUrl: 'https://www.buzzfeed.com/allisonjiang/best-places-to-rent-clothes',
    quote: 'You get the best of both worlds with this AAPI woman–owned, sustainable brand.'
  },
  {
    sourceName: 'Pop Sugar',
    sourceId: 'popsugar',
    sourceUrl: 'https://www.popsugar.com/fashion/armoire-clothing-subscription-review-48495019',
    quote: 'I tried clothing subscription service Armoire, and I actually had fun with fashion again.'
  }
])
</script>
<style scoped lang="scss">
.nyt {
    height: 80px;
}
.news-card {
    height: 230px;
}
.press-logo-wrapper {
    display: flex;
    width: 220px;
    height: 100px;
    justify-content: center;
    align-items: center;
}
</style>
