<template>
  <div class="bg-white text-center">
    <h3 class="col-12 mt-lg mb-md">
      Every Plan Includes:
    </h3>
    <div class="row row-gap-lg mb-xl">
      <div
        v-for="feature in planFeatures"
        :key="feature.title"
        class="plan-feature col-6 col-lg-2 col-md-4"
        :spacing="4">
        <div
          class="checkmark mb-xs">
          <SvgCheck
            width="24"
            height="24"/>
        </div>
        <p
          class="feature-text">
          {{ feature.title }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import SvgCheck from '@shared/components/icons/SvgCheck.vue'

const planFeatures = [
  {
    title: 'Access to 15,000+ styles'
  },
  {
    title: 'Personal styling on demand'
  },
  {
    title: 'Free shipping both ways'
  },
  {
    title: 'Free cleaning (no laundry!)'
  },
  {
    title: 'No-wait swaps—always have clothes'
  },
  {
    title: 'Cancel or pause anytime'
  }
]
</script>

<style lang="scss" scoped>
.plan-feature {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.checkmark {
  border-radius: 50%;
  background-color: $pewter;
  color: white;
  width: 32px;
  height: 32px;
  padding: $spacing-xxs;
}

.feature-text {
  max-width: 170px;
}
</style>
