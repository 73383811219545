<template>
  <HorizontalScroll
    title="Top Brands"
    secondary-button-style>
    <div
      v-if="rows.length > 0"
      class="d-flex flex-wrap">
      <div
        v-for="(row, rowIndex) in rows"
        :key="rowIndex"
        class="d-flex w-100 column-gap-sm column-gap-lg-lg mb-sm">
        <BaseLink
          v-for="brand in row"
          :key="brand.id"
          :to="brandLink(brand)"
          class="col-4 col-md-2 col-sm-3 brand-card"
          @click="trackClickEvent('Homepage', `Link to brand page for ${brand.name}`)">
          <img
            :src="brand.brandTileImgUrl"
            :alt="brand.name"
            class="brand-image">
        </Baselink>
      </div>
    </div>
    <div
      v-else
      class="d-flex w-100 flex-wrap">
      <div
        v-for="index in (isDesktop ? 1 : 2)"
        :key="index"
        class="d-flex w-100 column-gap-sm column-gap-lg-lg mb-sm">
        <div
          v-for="index in 20"
          :key="index"
          class="col-4 col-md-2 col-sm-3 placeholder"/>
      </div>
    </div>
  </HorizontalScroll>
</template>

<script setup>
import { computed, onBeforeMount } from 'vue'
import { storeToRefs } from 'pinia'
import { useBrandsStore } from '@shared/stores/brands.js'
import HorizontalScroll from '@shared/components/ADORN/HorizontalScroll.vue'
import useScreenSize from '@shared/composables/screenSize.js'
import BaseLink from '@shared/components/BaseLink.vue'
import { slugify } from '@/utils/stringParsing.js'
import useAnalytics from '@shared/composables/analytics.js'

const { isDesktop } = useScreenSize()
const { trackClickEvent } = useAnalytics()

const brandsStore = useBrandsStore()
const { topBrands } = storeToRefs(brandsStore)

onBeforeMount(async () => {
  await brandsStore.fetchTopBrands()
  topBrands.value = brandsStore.topBrands
})

const rows = computed(() => {
  if (isDesktop.value) {
    return [topBrands.value]
  } else {
    const half = Math.ceil(topBrands.value.length / 2)
    const firstRow = topBrands.value.slice(0, half)
    const secondRow = topBrands.value.slice(half)
    return [firstRow, secondRow]
  }
})

const brandLink = (brand) => {
  const slugifiedName = slugify(brand.name, { lower: true })
  return `/closet/brand/${slugifiedName}/${brand.id}`
}

</script>

<style scoped lang="scss">
.brand-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.placeholder {
  @include placeholder-loading-animation;
  aspect-ratio: 1 / 1;
}
</style>
