<template>
  <div>
    <div class="accordion-boxes">
      <div
        v-for="(boxinfo, index) in boxes"
        :key="index"
        class="tab">
        <input
          :id="'tab' + index"
          type="checkbox"
          name="tabs"
          class="tabinput">
        <label
          :for="'tab' + index">{{ boxinfo.header }}</label>
        <div class="tab-content">
          <p>{{ boxinfo.text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AccordionBoxes',
  props: {
    boxes: {
      type: Array,
      default: function () {
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.accordion-boxes {

  @include small {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.tab {
  position: relative;
  margin-bottom: 1px;
  width: 100%;
  color: #000;
  overflow: hidden;
  text-align: left;

  @include small {
    padding: 10px 5px 0 5px;
    width: calc(33% - 10px);
  }
}
.tab input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.tab label {
  position: relative;
  display: block;
  padding: 1em 40px 1em 20px;
  @include medium {
    min-height: 80px;
  }
  background: $origami;
  font-weight: bold;
  cursor: pointer;
}

.tab-content {
  max-height: 0;
  overflow: hidden;
  background: #1abc9c;
  -webkit-transition: max-height .35s;
  -o-transition: max-height .35s;
  transition: max-height .35s;
}
.tab-content {
  background: $origami;
}
.tab-content p {
  margin: 0 30px 0.8em 20px;
}
/* :checked */
.tab input:checked ~ .tab-content {
  max-height: 100vh;
  // border-width: 0px 1px 1px;
}
/* Icon */
.tab label::after {
  position: absolute;
  right: 2px;
  top: 12px;
  display: block;
  width: 3em;
  height: 3em;
  text-align: center;
  -webkit-transition: all .35s;
  -o-transition: all .35s;
  transition: all .35s;
}
.tab input[type=checkbox] + label::after {
  content: "+";
}
.tab input[type=checkbox]:checked + label::after {
  content: "-";
}
</style>
