import { isProd, isBeta } from '@shared/utils/environment.js'

export const facebookAppId = isProd || isBeta
  ? process.env.VUE_APP_FB_ID_PROD
  : process.env.VUE_APP_FB_ID_TEST

export const facebookInitOptions = {
  appId: facebookAppId,
  autoLogAppEvents: true,
  xfbml: true,
  version: 'v3.1'
}
Object.freeze(facebookInitOptions)

export function initFacebookSDK (options = {}, asyncInitCallback = null) {
  if (!window.fbAsyncInit) {
    window.fbAsyncInitCalled = false
    window.fbAsyncInit = function () {
      if (!window.fbAsyncInitCalled) {
        window.fbAsyncInitCalled = true
        window.FB.init({ ...facebookInitOptions, ...options })
        asyncInitCallback?.()
      }
    }
  } else if (!window.fbAsyncInitCalled && window.FB) {
    window.fbAsyncInit()
  }

  (function () {
    const scriptId = 'facebook-jssdk'
    if (document.getElementById(scriptId)) return
    const script = document.createElement('script')
    script.id = scriptId
    script.src = 'https://connect.facebook.net/en_US/sdk.js'
    document.head.appendChild(script)
  }())
}

const VueFB = {
  install (app, options) {
    initFacebookSDK(options, () => {
      app.config.globalProperties.$fb = window.FB
    })
  }
}
export default VueFB
