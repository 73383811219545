<template>
  <div class="linkblock">
    <p
      v-if="header"
      class="footer-header">
      {{ header }}
    </p>
    <div class="links">
      <div
        v-for="(link, index) in links"
        :key="index">
        <a
          :href="link.link"
          :target="link.openNewTab ? '_blank' : '_self'">
          {{ link.text }}
        </a>
      </div>
    </div>
    <div v-if="footer">
      <div
        v-for="(link, index) in footer"
        :key="index"
        class="footer-links">
        <a
          :href="link.link"
          :target="link.openNewTab ? '_blank' : '_self'">
          {{ link.text }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LinkBlock',
  props: {
    header: {
      type: String,
      default: ''
    },
    links: {
      type: Array,
      default: function () {
        return []
      }
    },
    footer: {
      type: Array,
      default: function () {
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.linkblock {
  text-align: left;
  margin: 0 10px 5px 5px;
  width: 200px;

  @include small {
    margin: 16px;
  }
}

.footer-links {
  font-size: 14px;
}

.footer-header {
  font-family: $headings-font-family;
  font-weight: $font-weight-semibold;
  font-size: 1rem;
  margin: 1rem 0 .5rem;
}
</style>
