import { formatPrice } from '@/utils/stringParsing.js'
import { getReferralDiscountPrice } from '@/homepage/utils/referrals.js'

export function getPlanPromoPrice (plan, referralCampaign) {
  if (!referralCampaign) return plan.promoPrice
  const amount = getReferralDiscountPrice(this.referralCampaign, plan)
  return formatPrice(amount, true)
}

export function parsePlanOnNextLine (planName) {
  return planName.replace(/(Plan)/g, '<br>$1')
}
