<template>
  <content-stack :spacing="12">
    <homepage-text
      tag="p"
      :class="textClass"
      styling-variant="copy-small">
      <slot/>
    </homepage-text>
    <get-started-button
      :location="location"
      :button-variant="buttonVariant"/>
  </content-stack>
</template>

<script>
import ContentStack from './ContentStack.vue'
import HomepageText from './HomepageText.vue'
import GetStartedButton from './GetStartedButton.vue'

export default {
  components: {
    ContentStack,
    HomepageText,
    GetStartedButton
  },
  props: {
    buttonVariant: {
      type: String,
      default: 'primary',
      validator: v => v.match(/(primary|secondary|tertiary)/)
    },
    textClass: {
      type: String,
      default: ''
    },
    location: {
      type: String,
      required: true
    }
  }
}
</script>
