<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 110 110"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="50"
      cy="50"
      r="50"
      class="circle"/>
    <polygon
      class="x"
      points="38 32, 70 50, 38 68"
      fill="#850D54"/>
  </svg>
</template>

<script>
export default {
  name: 'SvgPlay',
  props: {
    size: {
      type: Number,
      default: 24
    }
  }
}
</script>

<style lang="scss" scoped>
  .circle {
    fill: $white
  }

  .x {
    stroke: $orchid;
  }
</style>
