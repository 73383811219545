<template>
  <component
    :is="tag"
    :class="stylingVariant">
    <slot/>
  </component>
</template>

<script>
export default {
  props: {
    // This is the html tag that will be rendered
    // Tags should be used in descending order on a given page
    // i.e. h1 first, followed by h2, followed by h3, etc.
    // This promotes best html and accessibility practices
    tag: {
      type: String,
      default: 'p',
      validator: v => v.match(/(h1|h2|h3|h4|h5|h6|p|span)/)
    },
    // This is the styling variant of the text. This allows us
    // to apply any selected visual styling of text regardless of
    // its underlying tag
    stylingVariant: {
      type: String,
      default: 'copy-small',
      validator: v => v.match(/(siteheader|heading1|heading2|heading3|heading4|heading5|heading6|quote|quote-small|copy|copy-small|nav-link)/)
    }
  }
}
</script>

<style lang="scss" scoped>
// This uses the styling for the new homepage
// If test wins, we'll remove global styling from these
// elements, and we may have to reconcile landing pages
.siteheader, .heading1, .heading2, .heading3, .heading4, .heading5, .heading6, .nav-link {
  color: inherit;
  font-family: $headings-font-family;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin: 0;
}

.siteheader {
  font-size: $font-size-base * 1.5;
  line-height: $font-size-base * 2;

  @include medium {
    font-size: $font-size-base * 2.25;
    line-height: $font-size-base * 2.75;
  }
}

.heading1 {
  font-size: $font-size-base * 1.5;
  line-height: $font-size-base * 2;

  @include medium {
    font-size: $font-size-base * 1.875;
    line-height: $font-size-base * 2.5;
  }
}

.heading2 {
  font-size: $font-size-base * 1.125;
  line-height: $font-size-base * 1.5;

  @include medium {
    font-size: $font-size-base * 1.5;
    line-height: $font-size-base * 1.875;
  }
}

.heading3 {
  font-size: $font-size-base * .875;
  line-height: $font-size-base * 1.125;

  @include medium {
    font-size: $font-size-base * 1.125;
    line-height: $font-size-base * 1.5;
  }
}

.heading4 {
  font-size: $font-size-base * .875;
  line-height: $font-size-base * 1.125;
}

.heading5 {
  font-size: $font-size-base * .625;
  line-height: $font-size-base * .875;

  @include medium {
    font-size: $font-size-base * .875;
    line-height: $font-size-base * 1.125;
  }
}

.heading6 {
  font-size: $font-size-base * .625;
  line-height: $font-size-base * .875;
}

.quote {
  margin: 0;
  font-family: $accent-font-family;
  font-display: swap;
  font-size: $font-size-base * 1.125;
  line-height: $font-size-base * 1.5;
  letter-spacing: 0.04em;
  font-style: italic;

  @include medium {
    font-size: $font-size-base * 1.5;
    line-height: $font-size-base * 2.25;
  }
}

.nav-link {
  margin-block-start: 0;
  margin-block-end: 0;
  font-size: $font-size-base * .875;
  line-height: $font-size-base * 1.25;
}

.quote-small {
  font-family: $accent-font-family;
  font-display: swap;
  font-size: $font-size-base * .875;
  line-height: $font-size-base * 1.5;
  letter-spacing: 0.04em;
  font-style: italic;
  margin: 0;

  @include medium {
    font-size: $font-size-base * 1.125;
    line-height: $font-size-base * 1.5;
  }
}

.copy {
  margin: 0;
  font-family: $headings-font-family;
  font-size: $font-size-base;
  line-height: $font-size-base * 1.375;

  @include medium {
    font-family: $headings-font-family;
    font-size: $font-size-base * 1.5;
    line-height: $font-size-base * 2;
  }
}

.copy-small {
  margin: 0;
  font-family: $headings-font-family;
  font-size: $font-size-base * .875;
  line-height: $font-size-base * 1.125;

  @include medium {
    font-family: $headings-font-family;
    font-size: $font-size-base * 1.125;
    line-height: $font-size-base * 1.5;
  }
}

.copy-tiny {
  margin: 0;
  font-family: $headings-font-family;
  font-size: $font-size-base;
  line-height: $font-size-base * 1.125;
}

.text-start {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-white {
  color: $white;
}

.text-armor {
  color: $armor;
}

.text-plum {
  color: $plum;
}

.semi-bold {
  font-weight: $font-weight-semibold;
}

.normal {
  font-weight: $font-weight-normal;
}

.bold {
  font-weight: $font-weight-bold;
}
</style>
