<template>
  <homepage-section variant="origami">
    <content-wrapper :max-width="686">
      <content-stack :spacing="16">
        <content-stack
          class="description"
          :spacing="8">
          <homepage-text
            tag="h2"
            styling-variant="heading2">
            Like what you see?
          </homepage-text>
          <homepage-text>
            Join our community and never miss exclusive deals, seasonal style tips, trending looks, and member spotlights on bold women like you. Ready to dive in?
          </homepage-text>
        </content-stack>
        <email-capture
          success-header="Email submitted!"
          success-body="See you in your inbox"
          :source="emailSource"
          button-text="Sign me up!"/>
      </content-stack>
    </content-wrapper>
  </homepage-section>
</template>

<script>
import EmailCapture from '@/homepage/components/common/EmailCapture.vue'
import ContentStack from '@/homepage/components/common/ContentStack.vue'
import ContentWrapper from '@/homepage/components/common/ContentWrapper.vue'
import HomepageSection from '@/homepage/components/common/HomepageSection.vue'
import HomepageText from '@/homepage/components/common/HomepageText.vue'

export default {
  components: {
    ContentStack,
    ContentWrapper,
    HomepageSection,
    HomepageText,
    EmailCapture
  },
  props: {
    emailSource: {
      type: String,
      default: 'homepage'
    }
  }
}
</script>

<style lang="scss" scoped>
.description {
  margin-bottom: 24px !important; // !important to override ContentStack margin.
}
</style>
