<template>
  <div>
    <how-it-works-banner/>
    <the-basics v-bind="inViewportProps"/>
    <HomepageRedesignExperiment>
      <template #variantRedesign>
        <PlansForEveryBudget/>
      </template>
      <template #default>
        <plans-section v-bind="inViewportProps"/>
      </template>
    </HomepageRedesignExperiment>
    <renting-made-easy-section
      v-if="showVariant"
      :show-get-started="true"
      variant="origami"/>
    <questions-section v-bind="inViewportProps"/>
    <renting-made-easy
      v-if="!showVariant"
      v-bind="inViewportProps"
      :show-learn-more="false"
      variant="plum"/>
    <stylish-safe
      v-bind="inViewportProps"/>
    <decorative-divider
      v-if="!showVariant"
      class="vertical-divider"
      variant="vertical-dashed"/>
    <comparison-table
      v-bind="inViewportProps"/>
    <membership-perks
      v-bind="inViewportProps"/>
    <WhatMembersSay v-if="showVariant"/>
    <members-saying
      v-else
      v-bind="inViewportProps"/>
    <plans-callout
      v-bind="inViewportProps"
      variant="start-quiz"/>
    <email-capture-section
      v-if="showVariant"
      variant="white"
      class="mb-sm"/>
    <email-capture-homepage
      v-else
      v-bind="inViewportProps"
      email-source="how-it-works"/>
  </div>
</template>

<script>
import ComparisonTable from './ComparisonTable.vue'
import DecorativeDivider from '@/homepage/components/common/DecorativeDivider.vue'
import EmailCaptureHomepage from '@/homepage/components/homepage/EmailCaptureHomepage.vue'
import HowItWorksBanner from './HowItWorksBanner.vue'
import MembershipPerks from './MembershipPerks.vue'
import MembersSaying from './MembersSaying.vue'
import PlansCallout from '@/homepage/components/homepage/PlansCallout.vue'
import PlansSection from '@/homepage/components/common/PlansSection.vue'
import QuestionsSection from './QuestionsSection.vue'
import RentingMadeEasy from '@/homepage/components/homepage/RentingMadeEasy.vue'
import RentingMadeEasySection from '@/homepage/components/revamp/RentingMadeEasySection.vue'
import EmailCaptureSection from '@/homepage/components/revamp/EmailCaptureSection.vue'
import StylishSafe from './StylishSafe.vue'
import TheBasics from './TheBasics.vue'
import useAnalytics from '@shared/composables/analytics.js'
import PlansForEveryBudget from '@/homepage/components/revamp/PlansForEveryBudget.vue'
import HomepageRedesignExperiment from '@shared/components/HomepageRedesignExperiment.vue'
import WhatMembersSay from '@/homepage/components/revamp/WhatMembersSay.vue'
import { useHomepageRedesignExperiment } from '@shared/composables/experiment.js'

export default {
  components: {
    ComparisonTable,
    DecorativeDivider,
    EmailCaptureHomepage,
    EmailCaptureSection,
    HowItWorksBanner,
    MembershipPerks,
    MembersSaying,
    PlansSection,
    PlansCallout,
    QuestionsSection,
    RentingMadeEasy,
    RentingMadeEasySection,
    StylishSafe,
    TheBasics,
    PlansForEveryBudget,
    HomepageRedesignExperiment,
    WhatMembersSay
  },
  setup () {
    const { showVariant } = useHomepageRedesignExperiment()
    const { trackPageView } = useAnalytics()
    return {
      trackPageView,
      showVariant
    }
  },
  data () {
    return {
      inViewportProps: {
        'in-viewport-root-margin': '20% 0%',
        'in-viewport-once': true
      }
    }
  },
  activated () {
    this.trackPageView('How It Works Page')
  }
}
</script>

<style lang="scss" scoped>
.vertical-divider {
  // margin-top: 12;
  margin-bottom: -24px;
}
</style>
