<template>
  <div class="details-section">
    <h2 class="headline">
      The antidote to fast fashion.
    </h2>
    <div class="infocards">
      <info-card-base
        v-for="(infocard, index) in infocards"
        :key="index"
        class="infocard"
        :class="{'contain': infocard.contain }"
        :image-url="getImageUrl(infocard.image)">
        <div class="infocard-message">
          <p v-html="infocard.description"/>
        </div>
      </info-card-base>
    </div>
  </div>
</template>

<script>
import InfoCardBase from '@/homepage/components/common/InfoCardBase'
import { getImageUrl } from '@/homepage/utils/url.js'

export default {
  name: 'SustainabilityDetailsCards',
  components: {
    InfoCardBase
  },
  data () {
    return {
      infocards: [{
        image: 'sustainability/rinse.gif',
        placeholder: 'how-it-works/delivery.jpg',
        description: 'The average Armoire garment is rented <strong>10-16 times</strong> before being retired.'
      },
      {
        image: 'how-it-works/delivery.jpg',
        placeholder: 'how-it-works/delivery.jpg',
        description: 'We use minimal packaging and <strong>recyclable shipping bags</strong> to deliver your clothes responsibly.'
      },
      {
        image: 'sustainability/upcycle.gif',
        placeholder: 'how-it-works/delivery.jpg',
        description: 'Our team <strong>mends and upcycles</strong> our clothing to prolong the lifecycle of each piece.',
        contain: true
      },
      {
        image: 'sustainability/volunteering.png',
        placeholder: 'how-it-works/delivery.jpg',
        description: '<strong>Donations</strong> to Dress For Success, Mary’s Place, and other community organizations ensure our clothing gets a second life.'
      }]
    }
  },
  methods: {
    getImageUrl
  }
}
</script>

<style lang="scss" scoped>
.details-section {
  background-color: $origami;
  padding: 24px 40px;

  @include medium {
      padding: 48px;

  }
}

.infocard-message {
  height: fit-content;

  p {
    margin: 0;
  }

  @include small {
    height: 112px;
  }
}

.infocards {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  justify-content: center;
  margin: 0 auto;

  @include medium {
    padding: 0 24px;
  }

  @include extralarge {
    justify-content: space-between;
  }

  .infocard {
    width: 100%;
    max-width: 280px;
    margin: 16px 8px;

    @include medium {
      width: 44%;
      margin: 12px 12px;
    }

    @include extralarge {
      width: 40%;
      max-width: 240px;
    }

    &.contain {
      :global(.infocard-inner-image) {
        object-fit: contain;
      }
    }

    :global(.infocard-image) {
      height: 44vw;

      @include small {
        height: 240px;

      }
    }
  }
}
</style>
