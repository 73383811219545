import { useStore } from 'vuex'
import useCollections from './collections.js'
import { computed, onMounted } from 'vue'

export default function useMyStuff () {
  const store = useStore()
  const myCollections = useCollections()

  const sections = computed(() => store.getters['closet/myStuffSections'] ?? [])
  const myCollectionsSections = computed(() => sections.value.filter(section =>
    (section.source !== 'be_the_buyer' || section.length > 0) && section.source !== 'rewear' && section.source !== 'stylist'
  ))
  const myCollectionsSectionIds = computed(() => myCollectionsSections.value.map(section => section.id))

  const myStuffSectionsLoaded = computed(() => store.state.closet.myStuffSectionsLoaded)
  onMounted(async () => {
    if (!myStuffSectionsLoaded.value) {
      await store.dispatch('closet/getMyStuffSections')
    }
  })

  const stylistSections = computed(() => sections.value.filter(section => section.source === 'stylist'))

  return {
    ...myCollections,
    stylistSections,
    myCollectionsSections,
    myCollectionsSectionIds
  }
}
