<template>
  <image-half-section
    id="sustainability"
    show-photo-on-mobile
    alt="Armoire member wearing pink dress in green trees"
    image-url="sustainability/sustainability-banner-l.jpg"
    mobile-image-url="sustainability/sustainability-banner-m.jpg"
    placeholder-image-url="sustainability/sustainability-banner-t.jpg">
    <div>
      <h2>Join the sustainable fashion movement</h2>
      <p>We’re always thinking about your next outfit, and we’re also thinking about the impact of your next 100 outfits. Did you know renting your wardrobe is <strong>82% more carbon efficient</strong> than creating a new generation of clothes?</p>
    </div>
    <primary-button
      href="/signup/intro"
      class="renting-button">
      Start Renting
    </primary-button>
  </image-half-section>
</template>

<script>
import ImageHalfSection from '@/homepage/components/common/ImageHalfSection'
import PrimaryButton from '@/homepage/components/common/PrimaryButton.vue'
export default {
  name: 'TopBanner',
  components: {
    ImageHalfSection,
    PrimaryButton
  }
}
</script>

<style lang="scss" scoped>
  .renting-button {
    margin-top: 16px;
  }

  #sustainability {
    background-color: $origami;
  }
</style>
