<template>
  <div class="row bg-origami pb-xl">
    <HomepageSection title="Our Top Curated Collections"/>
    <MyClosetSectionPreview
      v-for="collection in featuredCollections"
      :key="collection.id"
      :section-id="collection.id"
      prospect
      class="col-12 pb-xl"/>
  </div>
</template>
<script setup>
import { computed, provide } from 'vue'
import MyClosetSectionPreview from '@/components/closet/MyClosetSectionPreview.vue'
import { useClosetStore } from '@shared/stores/closet.js'
import { getSectionHref } from '@shared/utils/route.js'
import { storeToRefs } from 'pinia'
import HomepageSection from './revamp/HomepageSection.vue'

const closetStore = useClosetStore()
const { homepageSections } = storeToRefs(closetStore)

const collections = computed(() => homepageSections.value?.map(section => {
  return {
    id: section.id,
    title: section.name,
    imageUrl: section.bannerImageUrl,
    routeLocation: getSectionHref(section)
  }
}) ?? [])
const featuredCollections = computed(() => collections.value.slice(0, 3))

provide('useClosetDetailFlyout', false)
</script>

<style lang="scss" scoped>
.tile-grid {
  display: grid;
  gap: $spacing-md;
  @include media-desktop {
    grid-template-columns: repeat(4, 1fr);
  }
  @include media-tablet {
    grid-template-columns: repeat(2, 1fr);
    margin-top: $spacing-md;

  }
  @include media-mobile {
    grid-template-columns: 1fr;
    margin-top: $spacing-md;
  }
}
</style>
