<template>
  <div
    class="row hero-section text-origami bg-plum text-center text-lg-start">
    <img
      class="hero-image col-12 col-lg-6 p-0 object-fit-cover"
      :src="section?.blocks[0]?.image.preprocessedUrl"
      alt="Hero Image">
    <div class="my-lg d-flex flex-column justify-content-center align-items-center align-items-lg-start col-12 col-lg-6">
      <div class="hero-content ps-lg-lg mx-auto">
        <h1 class="text-capitalize text-origami mb-lg-md">
          {{ section?.title }}
        </h1>
        <p class="mb-lg mb-lg-md">
          {{ section?.blocks[0]?.text }}
        </p>
        <GetStartedButton
          location="Hero Image"
          variant="secondary"
          fixed-width
          class="text-uppercase"/>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useGenericContentStore } from '@/store/modules/genericContent'
import GetStartedButton from '@/homepage/components/GetStartedButton.vue'
import { computed } from 'vue'

const store = useGenericContentStore()
const section = computed(() => store.heroImageSection)
</script>

<style lang="scss" scoped>
.hero-image {
  object-position: top left;
  height: 30dvh;
  @include media-desktop {
    height: auto;
    aspect-ratio: 4/3;
  }
}
.hero-content {
  max-width: 450px;
  @include media-tablet-or-smaller {
    padding: 0;
    max-width: 80%;
    margin: 0 auto;
  }
}
</style>
