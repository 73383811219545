<template>
  <homepage-section variant="white">
    <content-wrapper>
      <content-stack :spacing="24">
        <decorative-divider/>
        <content-wrapper :max-width="686">
          <homepage-text
            tag="h2"
            styling-variant="heading1">
            What our members are saying
          </homepage-text>
        </content-wrapper>
        <content-row class="flex-wrap align-items-start">
          <content-stack
            v-for="memberQuote in memberQuotes"
            :key="memberQuote.id"
            class="member-quote">
            <homepage-image
              v-if="inViewport.now"
              :alt="`${memberQuote.name} wearing Armoire outfit`"
              :webp-source="`howitworks/jan2022/memberssaying/${memberQuote.id}.webp`"
              :source="`howitworks/jan2022/memberssaying/${memberQuote.id}.jpg`"
              source-type="image/jpeg"
              width="100%"
              height="auto"/>
            <content-stack :spacing="8">
              <content-stack :spacing="2">
                <homepage-text
                  tag="h3"
                  styling-variant="heading3">
                  {{ memberQuote.name }}
                </homepage-text>
                <homepage-text
                  tag="p"
                  class="normal"
                  styling-variant="heading3">
                  Member since {{ memberQuote.memberSince }}
                </homepage-text>
              </content-stack>
              <homepage-text
                tag="p"
                :styling-variant="isMobile ? 'quote' : 'quote-small'">
                "{{ memberQuote.quote }}"
              </homepage-text>
            </content-stack>
          </content-stack>
        </content-row>
        <decorative-divider variant="bottom"/>
      </content-stack>
    </content-wrapper>
  </homepage-section>
</template>

<script>
import ContentRow from '@/homepage/components/common/ContentRow.vue'
import ContentStack from '@/homepage/components/common/ContentStack.vue'
import ContentWrapper from '@/homepage/components/common/ContentWrapper.vue'
import DecorativeDivider from '@/homepage/components/common/DecorativeDivider.vue'
import HomepageImage from '@/homepage/components/common/HomepageImage.vue'
import HomepageSection from '@/homepage/components/common/HomepageSection.vue'
import HomepageText from '@/homepage/components/common/HomepageText.vue'

import inViewport from 'vue-in-viewport-mixin'

export default {
  components: {
    ContentStack,
    ContentWrapper,
    DecorativeDivider,
    HomepageImage,
    HomepageSection,
    HomepageText,
    ContentRow
  },
  mixins: [inViewport],
  data () {
    return {
      memberQuotes: [
        {
          id: 'missa',
          name: 'Missa',
          memberSince: '2020',
          quote: 'With rentals, you always have a new-to-you fashion moment without the guilt or expense.'
        },
        {
          id: 'rachel',
          name: 'Rachel',
          memberSince: '2020',
          quote: 'I\'m one of those "funky between misses and plus" people. Armoire has truly upped the rental game for me — I\'m having so much fun with this service.'
        },
        {
          id: 'laura',
          name: 'Laura',
          memberSince: '2020',
          quote: 'Armoire always has the perfect item for any event, without the splurge!'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.member-quote {
  border-top: $default-border;
  width: 100%;
  padding: 4px 0 12px;
  max-width: 334px;

  @include large {
    width: 30%;
    flex-grow: 0;
  }
}
</style>
