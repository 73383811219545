<template>
  <div
    :class="[variant, { 'page-section-padding': useDefaultPadding }]">
    <slot/>
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      default: 'plum',
      validator: v => v.match(/(plum|armor|white|origami)/),
      type: String
    },
    useDefaultPadding: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.page-section-padding {
  padding: 40px 20px;

  @include medium {
    padding: 60px 20px;
  }
}

.plum {
  background-color: $plum;
}

.armor {
  background-color: $armor;
}

.white {
  background-color: $white;
}

.origami {
  background-color: rgba($origami, .5);
}
</style>
