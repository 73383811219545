<template>
  <homepage-section variant="white">
    <content-stack>
      <decorative-divider/>
      <content-wrapper>
        <content-stack>
          <content-wrapper :max-width="686">
            <content-stack :spacing="8">
              <homepage-text
                tag="h2"
                class="text-center"
                styling-variant="heading1">
                Stylish, Smart, Personal
              </homepage-text>
              <homepage-text>
                Our sophisticated style algorithm continuously improves with your selections, likes and dislikes, and reviews, giving you better recommendations every time. Paired with our team of personal stylists, you'll never have to pick out an outfit alone again.
              </homepage-text>
            </content-stack>
          </content-wrapper>
          <content-wrapper :max-width="840">
            <content-stack :spacing="24">
              <homepage-text
                tag="h6">
                The perfect combo of Art (personal stylists) <br>& Science (data)
              </homepage-text>
              <div class="video-container">
                <video
                  v-if="inViewport.now"
                  loop
                  muted
                  alt="Decorative video visualizing the combination of art and science. Armoire stylist headshot next to Armoire dress."
                  width="100%"
                  height="auto"
                  class="section-image"
                  autoplay="true">
                  <source
                    :src="isMobile ?
                      getImageUrl('loopingvideos/stylishsmart_mobile.mp4') :
                      getImageUrl('loopingvideos/stylishsmart.mp4')"
                    type="video/mp4">
                </video>
              </div>
              <homepage-get-started-lockup location="Stylish Smart">
                Our Style Quiz is just the beginning — get started today.
              </homepage-get-started-lockup>
            </content-stack>
          </content-wrapper>
        </content-stack>
      </content-wrapper>
      <decorative-divider variant="bottom"/>
    </content-stack>
  </homepage-section>
</template>

<script>
import ContentStack from '@/homepage/components/common/ContentStack.vue'
import ContentWrapper from '@/homepage/components/common/ContentWrapper.vue'
import DecorativeDivider from '@/homepage/components/common/DecorativeDivider.vue'
import HomepageSection from '@/homepage/components/common/HomepageSection.vue'
import HomepageText from '@/homepage/components/common/HomepageText.vue'
import HomepageGetStartedLockup from '@/homepage/components/common/HomepageGetStartedLockup.vue'

import inViewport from 'vue-in-viewport-mixin'
import { getImageUrl } from '@/homepage/utils/url.js'

export default {
  components: {
    HomepageSection,
    HomepageText,
    ContentStack,
    HomepageGetStartedLockup,
    ContentWrapper,
    DecorativeDivider
  },
  mixins: [inViewport],
  methods: {
    getImageUrl
  }
}
</script>
