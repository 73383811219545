<template>
  <homepage-section
    variant="armor"
    class="benefits-banner"
    :use-default-padding="false">
    <content-row
      v-if="isMobile"
      :spacing="0"
      alignment="left"
      class="rotating-text">
      <svg-check
        class="rotating-check"
        variant="inverse"/>
      <homepage-text
        tag="p"
        class="text-white"
        styling-variant="heading3">
        Free
      </homepage-text>
      <homepage-text
        tag="p"
        class="text-white benefit"
        styling-variant="heading3">
        {{ benefits[activeIndex] }}
      </homepage-text>
    </content-row>
    <content-row
      v-else>
      <content-row
        v-for="(benefit, idx) in benefits"
        :key="benefit"
        :spacing="12">
        <decorative-divider
          v-if="idx !== 0 && !isMobile"
          class="divider"
          variant="vertical"/>
        <svg-check variant="inverse"/>
        <homepage-text
          tag="p"
          class="text-white"
          styling-variant="heading3">
          Free {{ benefit }}
        </homepage-text>
      </content-row>
    </content-row>
  </homepage-section>
</template>

<script>
import SvgCheck from '@/homepage/components/common/SvgCheck.vue'
import ContentRow from '@/homepage/components/common/ContentRow.vue'
import DecorativeDivider from '@/homepage/components/common/DecorativeDivider.vue'
import HomepageSection from '@/homepage/components/common/HomepageSection.vue'
import HomepageText from '@/homepage/components/common/HomepageText.vue'

export default {
  components: {
    ContentRow,
    HomepageSection,
    HomepageText,
    SvgCheck,
    DecorativeDivider
  },
  data () {
    return {
      activeIndex: 0,
      benefits: ['Shipping', 'Cleaning', 'Styling']
    }
  },
  mounted () {
    setInterval(() => {
      this.activeIndex = (this.activeIndex + 1) % this.benefits.length
    }, 2000)
  }
}
</script>

<style lang="scss" scoped>
.benefits-banner {
  padding: 12px 0;
}

.rotating-check {
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 12px;
}

.rotating-text {
  width: 252px;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 auto;

  .benefit {
    width: 100px;
    text-align: left;
    margin-left: 8px;
  }
}

.divider {
  margin-right: 20px;
}
</style>
