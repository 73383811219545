<template>
  <div class="row">
    <HomepageSection
      title="What Members Say"/>
    <AutoScrollCarousel
      v-if="section"
      class="col-12"
      :items="section.blocks"
      :items-per-slide="{mobile: 2, tablet: 3, desktop: 4}"
      :show-buttons="!isMobile"
      :show-indicators="false">
      <template #default="{ item }">
        <TestimonialCard
          v-if="item.image?.preprocessedUrl"
          :text="item.text"
          :image-url="item.image.preprocessedUrl"
          :image-alt-text="item.image.imageAltText"/>
      </template>
    </AutoScrollCarousel>
    <AutoScrollCarousel
      v-else
      :items="Array(4).fill({})"
      :items-per-slide="{mobile: 2, tablet: 3, desktop: 4}"
      :show-indicators="false">
      <template #default>
        <PreviewImagePlaceholder/>
      </template>
    </AutoScrollCarousel>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useGenericContentStore } from '@/store/modules/genericContent'
import AutoScrollCarousel from '@shared/components/ADORN/AutoscrollCarousel.vue'
import TestimonialCard from '@/homepage/components/revamp/WhatMembersSay/TestimonialCard.vue'
import PreviewImagePlaceholder from '@shared/components/ADORN/PreviewImagePlaceholder.vue'
import HomepageSection from './HomepageSection.vue'

const store = useGenericContentStore()
const section = computed(() => store.whatMembersSaySection)
</script>
