<template>
  <picture>
    <source
      v-if="webpSource"
      :srcset="getImageUrl(webpSource)"
      type="image/webp">
    <source
      :srcset="getImageUrl(source)"
      :type="sourceType">
    <img
      :height="height"
      :width="width"
      :src="getImageUrl(source)"
      :alt="alt">
  </picture>
</template>

<script>
import { getImageUrl } from '@/homepage/utils/url.js'

export default {
  props: {
    // Pass for browsers that are able to handle webp images
    webpSource: {
      type: String,
      required: true
    },
    // Pass as a backup for all other browsers
    source: {
      type: String,
      required: true
    },
    // Pass to indicate the source type of the back up image
    sourceType: {
      type: String,
      default: 'image/jpeg'
    },
    // Pass for accessibility. A description of the image for screen readers.
    alt: {
      type: String,
      required: true
    },
    height: {
      type: [Number, String],
      default: null
    },
    width: {
      type: [Number, String],
      default: null
    }
  },
  methods: {
    getImageUrl
  }
}
</script>
